import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
      {...props}
    >
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="icons/tile-types/black/time_delay">
          <rect
            fillOpacity={0}
            fill="#FFFFFF"
            x={0}
            y={0}
            width={24}
            height={24}
          />
          <polygon
            fill="#000000"
            points="16.5563492 1 23 7.44365081 23 16.5563492 16.5563492 23 7.44365081 23 1 16.5563492 1 7.44365081 7.44365081 1"
          />
          <g transform="translate(8.000000, 5.000000)" fill="#FFFFFF">
            <path
              d="M0,0 L0,4 L0.00666666667,4 L0,4.00666667 L2.66666667,6.66666667 L0,9.33333333 L0.00666666667,9.34 L0,9.34 L0,13.3333333 L8,13.3333333 L8,9.34 L7.99333333,9.34 L8,9.33333333 L5.33333333,6.66666667 L8,4.00666667 L7.99333333,4 L8,4 L8,0 L0,0 Z M6.66666667,9.66666667 L6.66666667,12 L1.33333333,12 L1.33333333,9.66666667 L4,7 L6.66666667,9.66666667 Z M4,6.33333333 L1.33333333,3.66666667 L1.33333333,1.33333333 L6.66666667,1.33333333 L6.66666667,3.66666667 L4,6.33333333 Z"
              fillRule="nonzero"
            />
            <path d="M1,3 L7.66666667,3 L7.66666667,4 C5.93468786,5.68066406 4.71246564,6.52099609 4,6.52099609 C3.28753436,6.52099609 2.28753436,5.68066406 1,4 L1,3 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

import React from 'react';

export default (props) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs />
      <g
        id="Symbols:-Icons"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g stroke="#000000" strokeWidth={1.5}>
          <path d="M20.4938811,17.8759653 L17.25,12.1991733 L17.25,7 C17.25,4.10050506 14.8994949,1.75 12,1.75 C9.10050506,1.75 6.75,4.10050506 6.75,7 L6.75,12.1991733 L3.50611889,17.8759653 C3.48453351,17.9137397 3.47317968,17.9564933 3.47317968,18 C3.47317968,18.1380712 3.58510849,18.25 3.72317968,18.25 L20.2768203,18.25 C20.3203271,18.25 20.3630806,18.2386462 20.4008551,18.2170608 C20.5207344,18.1485583 20.5623836,17.9958446 20.4938811,17.8759653 Z" />
          <path d="M12,21.3005538 C13.6568542,21.3005538 15,19.957408 15,18.3005538 C14.0291748,18.2965254 10.0280151,18.3163008 9,18.3005538 C9,19.957408 10.3431458,21.3005538 12,21.3005538 Z" />
        </g>
      </g>
    </svg>
  );
};

import styled from 'styled-components';
import HorizontalWrapper from './horizontalWrapper';
import React, { createRef, useEffect, useState } from 'react';

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  max-width: 300px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  & input {
    outline: none;
    border: none !important;
  }
  & div {
    padding: 0 0.5rem;
  }
`;

export const TimeInput = React.forwardRef(
  (
    { type, value, onChange, forwardRef, onFocus, errors, onBlur, ...props },
    ref,
  ) => {
    const hoursInputRef = createRef();
    const minutesInputRef = createRef();
    const [hours, setHours] = useState(
      value?.length ? value.split(':')[0] : '',
    );
    const [minutes, setMinutes] = useState(
      value?.length ? value.split(':')[1] : '',
    );
    const handleChange = (event) => {
      const { name, value } = event.target;
      if (
        !isNaN(value) &&
        Number(value) >= 0 &&
        Number.isInteger(Number(value))
      ) {
        if (name === 'hours') {
          setHours(value?.length ? value : '');
          if (minutes?.length <= 0 && value.length > 0) {
            setMinutes('0');
          }
        } else if (name === 'minutes') {
          if (parseInt(value) > 59) {
            const hrs = value / 60;
            const mins = value % 60;
            setHours(String(parseInt(hours) + parseInt(hrs)));
            setMinutes(mins);
          } else {
            setMinutes(value?.length > 0 ? value : '');
          }
          if (hours?.length <= 0 && value.length > 0) {
            setHours('0');
          }
        }
      } else {
        if (name === 'hours') {
          hoursInputRef.current.value =
            value?.length > 0 ? hoursInputRef.current.value : '';
        } else {
          minutesInputRef.current.value =
            value?.length > 0 ? minutesInputRef.current.value : '';
        }
      }
    };

    useEffect(() => {
      if (hours?.length > 0 && minutes?.length > 0) {
        onChange(`${hours}:${minutes}`);
        onBlur();
      } else {
        onChange('');
        onBlur();
      }
    }, [hours, minutes, onBlur, onChange]);

    return (
      <TimeInputWrapper className={props.className} {...props} ref={ref}>
        <input
          name="hours"
          placeholder="HH"
          value={hours}
          type="number"
          onChange={(e) => handleChange(e)}
          ref={hoursInputRef}
          step={1}
        />
        <div>:</div>
        <input
          name="minutes"
          placeholder="mm"
          value={minutes}
          type="number"
          onChange={(e) => handleChange(e)}
          ref={minutesInputRef}
          step={1}
        />
      </TimeInputWrapper>
    );
  },
);

const HorizontalTextInput = (props) => (
  <HorizontalWrapper {...props} fieldType="horizontalTextInput">
    {props.type === 'time' ? (
      <TimeInput className={`${props.className} text`} {...props.input} />
    ) : (
      <input
        {...props.input}
        className={`${props.className} text`}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type}
      />
    )}
  </HorizontalWrapper>
);

HorizontalTextInput.defaultProps = {
  disabled: false,
  placeholder: ``,
  className: ``,
  type: `text`,
};
HorizontalTextInput.handlesErrorDisplay = HorizontalWrapper.handlesErrorDisplay;

export default HorizontalTextInput;

import axios from 'axios';
import { logError } from 'src/utils/errorLogger';
import { imageGetUrl } from '../../../api';

const config = (type) => ({
  headers: {
    'Content-Type': type,
  },
});

export default ({ data, study_slug }, cb) => {
  const [file] = data;

  imageGetUrl(study_slug)
    .then(async ({ data: { upload_url, public_url } }) => {
      const { status } = await axios.put(upload_url, file, config(file.type));

      if (status === 200) {
        cb(public_url);
      } else {
        cb(false);
      }
    })
    .catch((error) => {
      logError(error);
      return cb(false);
    });
};

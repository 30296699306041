import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toastr } from 'react-redux-toastr';
import { QAOverridesService } from 'src/api/qaOverrides';

export const useQAOverrides = (graphId: string) => {
  const { data, ...rest } = useQuery(
    ['qa-overrides', graphId],
    () => QAOverridesService.getAll({ graphId }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );
  return {
    data,
    ...rest,
  };
};

export const usePostQAOverrides = (graphId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: QAOverridesService.save,
    onSuccess: () => {
      queryClient.fetchQuery(['qa-overrides', graphId]);
      toastr.success('Success', 'QA Override successfully created');
    },
  });
};

export const useUpdateQAOverride = (graphId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: QAOverridesService.update,
    onSuccess: () => {
      queryClient.fetchQuery(['qa-overrides', graphId]);
      toastr.success('Success', 'QA Override successfully updated');
    },
  });
};

export const useDeleteQAOverride = (graphId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: QAOverridesService.delete,
    onSuccess: () => {
      queryClient.fetchQuery(['qa-overrides', graphId]);
      toastr.success('Success', 'QA Override successfully deleted');
    },
  });
};

"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deslugify = deslugify;
exports.numberify = numberify;
exports.slugify = slugify;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.date.to-string.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.string.split.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.join.js");
function deslugify(text) {
  text = text.toString().replace(/_/g, ' ').replace(/-/g, ' ');
  return titleCase(text);
}
function numberify(number) {
  var num = Number(number);
  if (isNaN(num)) {
    return 0;
  }
  return num;
}
function slugify(text) {
  return text.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
}
function titleCase(str) {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}
import { LoadingIndicator } from '@evidation/ui';
import { createRef, useMemo, useState } from 'react';
import styled from 'styled-components';
import { transformRawData } from './qaOverrides.utils';
import {
  useDeleteQAOverride,
  usePostQAOverrides,
  useQAOverrides,
  useUpdateQAOverride,
} from './useQaOverrides';
import QAOverrideForm from './qaOverride.form';
import QAOverridesTable from './qaOverrides.table';
import { QAOverridesData } from 'src/api/qaOverrides';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
  background-color: white;
  height: auto;
`;

const Notification = styled.div`
  display: flex;
  justify-content: center;
`;
const QAOverrides: React.FC<{ graph_id: string }> = ({ graph_id }) => {
  const formRef = createRef<HTMLFormElement>();
  const [overrideToUpdate, setOverrideToUpdate] = useState<QAOverridesData>();
  const { data, isLoading, isError } = useQAOverrides(graph_id);
  const { mutate: postOverride, isLoading: isSending } =
    usePostQAOverrides(graph_id);

  const { mutate: deleteOverride } = useDeleteQAOverride(graph_id);
  const { mutate: updateOverride, isLoading: isUpdating } =
    useUpdateQAOverride(graph_id);
  const tableData = useMemo(() => {
    return data ? transformRawData(data) : [];
  }, [data]);
  const handleUpdateOverride = (override) => {
    formRef.current?.scrollIntoView();
    setOverrideToUpdate({
      attributes: override.attributes,
      relationships: override.relationships,
      type: override.type,
      id: override.id,
    });
  };
  const handleDeleteOverride = (override) => {
    deleteOverride({ graphId: graph_id, overrideId: override.id });
  };

  return (
    <Container>
      <h1>QA Setting Overrides</h1>
      <p>
        These are configuration options that are available for temporary
        modifications, these modifications are in effect until the study reaches
        "recruiting" status
      </p>
      {isLoading ? (
        <LoadingIndicator />
      ) : isError ? (
        <Notification>
          <h1>
            There was an error trying to fetch QA Overrides, try again latter
          </h1>
        </Notification>
      ) : (
        <>
          <QAOverrideForm
            key={overrideToUpdate?.id || 'newOverride'}
            graphId={graph_id}
            sideEffects={data?.included || []}
            postOverride={(override) => postOverride(override)}
            isSending={isSending || isUpdating}
            updateOverride={(override) => {
              updateOverride(override);
              setOverrideToUpdate(undefined);
            }}
            defaultValue={overrideToUpdate}
            formRef={formRef}
            cancelEdit={() => setOverrideToUpdate(undefined)}
            currentOverrides={data?.data || []}
          />
          {tableData?.length ? (
            <QAOverridesTable
              tableData={tableData}
              deleteOverride={handleDeleteOverride}
              updateOverride={handleUpdateOverride}
            />
          ) : (
            <Notification>
              <h1>There are no defined QA Overrides yet</h1>
            </Notification>
          )}
        </>
      )}
    </Container>
  );
};

export default QAOverrides;

import React from 'react';

export default function VisOn({ color = `#111`, ...props }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g transform="translate(-480.000000, -64.000000)" />
        <g fill={color}>
          <g transform="translate(2.000000, 4.000000)">
            <path d="M21,7.2187522 C20.9980313,6.91097095 20.9284688,6.60253345 20.811,6.26653345 C20.3955937,5.10365845 19.3554375,3.57853345 17.642625,2.2903147 C15.9324375,1.00865845 13.5384375,-0.00131030119 10.5,2.19881384e-06 C7.0258125,-0.00196655119 4.3995,1.31840845 2.664375,2.85403345 C1.79484375,3.6225022 1.1431875,4.4428147 0.6995625,5.19422095 C0.47775,5.5702522 0.30778125,5.93053345 0.189,6.26653345 C0.07153125,6.60253345 0.0013125,6.91097095 0,7.2187522 C0,7.58165845 0.294,7.8750022 0.65625,7.8750022 C1.0185,7.8750022 1.3125,7.58165845 1.3125,7.2187522 C1.31053125,7.1321272 1.341375,6.93984595 1.4266875,6.70228345 C1.7206875,5.85309595 2.6341875,4.46447095 4.1435625,3.3416272 C5.65621875,2.21222095 7.75359375,1.3138147 10.5,1.3125022 C13.636875,1.3151272 15.9324375,2.48653345 17.46675,3.8377522 C18.2312812,4.51303345 18.8009063,5.23490845 19.1697187,5.86097095 C19.3547813,6.1740022 19.4886563,6.4627522 19.5733125,6.70228345 C19.6579687,6.93984595 19.6888125,7.1321272 19.6875,7.2187522 C19.6875,7.58165845 19.9815,7.8750022 20.34375,7.8750022 C20.706,7.8750022 21,7.58165845 21,7.2187522" />
            <path d="M19.6875,7.2187522 C19.6888125,7.30603345 19.6579687,7.4983147 19.5733125,7.7358772 C19.2793125,8.58440845 18.3658125,9.97303345 16.8564375,11.0965334 C15.3437812,12.2252834 13.2464063,13.1236897 10.5,13.1250022 C7.363125,13.1230334 5.0675625,11.9509709 3.53325,10.5997522 C2.7680625,9.9251272 2.19909375,9.20259595 1.829625,8.5758772 C1.64521875,8.2648147 1.51134375,7.9747522 1.4266875,7.7358772 C1.341375,7.4983147 1.31053125,7.30603345 1.3125,7.2187522 C1.3125,6.8565022 1.0185,6.5625022 0.65625,6.5625022 C0.294,6.5625022 0,6.8565022 0,7.2187522 C0.0013125,7.52653345 0.07153125,7.8356272 0.189,8.17228345 C0.60440625,9.33515845 1.6445625,10.8596272 3.357375,12.1471897 C5.0675625,13.4288459 7.4615625,14.4388147 10.5,14.4375022 C13.9741875,14.4394709 16.6005,13.1190959 18.335625,11.5828147 C19.2051563,10.8150022 19.8568125,9.9946897 20.3004375,9.2439397 C20.52225,8.8672522 20.6922187,8.5076272 20.811,8.17228345 C20.9284688,7.8356272 20.9980313,7.52653345 21,7.2187522 C21,6.8565022 20.706,6.5625022 20.34375,6.5625022 C19.9815,6.5625022 19.6875,6.8565022 19.6875,7.2187522" />
            <path d="M13.78125,7.2187522 L14.4375,7.2187522 C14.4368438,5.04459595 12.6748125,3.2825647 10.5,3.2812522 C8.3251875,3.2825647 6.56315625,5.04459595 6.5625,7.2187522 C6.56315625,9.3935647 8.3251875,11.1555959 10.5,11.1562522 C12.6748125,11.1555959 14.4368438,9.3935647 14.4375,7.2187522 L13.125,7.2187522 C13.125,7.94522095 12.8323125,8.5981897 12.355875,9.0746272 C11.8794375,9.55172095 11.227125,9.8437522 10.5,9.8437522 C9.772875,9.8437522 9.1205625,9.55172095 8.64346875,9.0746272 C8.1676875,8.5981897 7.875,7.94522095 7.875,7.2187522 C7.875,6.49228345 8.1676875,5.8393147 8.64346875,5.3628772 C9.1205625,4.8864397 9.772875,4.59440845 10.5,4.5937522 C11.227125,4.59440845 11.8794375,4.8864397 12.355875,5.3628772 C12.8323125,5.8393147 13.125,6.49228345 13.125,7.2187522 L13.78125,7.2187522" />
          </g>
        </g>
      </g>
    </svg>
  );
}

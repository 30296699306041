import sanitizeHtml from 'sanitize-html';
import {
  validateJson,
  validateMaskCustomMax,
  ValidateMaxNumber,
  sliderPositiveSteps,
} from './common_validations';
import { isFinite } from 'lodash';
import { generateCountryList, transformCsvAdditionalPayload } from './utils';
import IconSubtract from 'src/components/icon/IconSubtract';
import { event_statuses } from 'src/constants';
import { parseSlug } from './common';

export const hintText = {
  name: 'hint_text',
  label: 'Hint text',
  interface: 'rte',
  inputStyle: 'ehp',
};

const filterValues = (v, index, value, source, sourceIndex, staticSource) => {
  const valueAsNumber = String(value).length > 0 ? Number(value) : undefined;
  const vAsNumber = String(v).length > 0 ? Number(v) : undefined;
  if (source === staticSource) {
    return vAsNumber === valueAsNumber && Number(sourceIndex) !== index;
  } else {
    return vAsNumber === valueAsNumber;
  }
};

const isUniqueValue = (value, state, sourceIndex, source) => {
  const exclusiveAnswersValues =
    state?.mutually_exclusive_answers?.map((val) => val.answer) || [];
  const exclusiveAnswersValuesCoincidences = exclusiveAnswersValues.filter(
    (v, index) =>
      filterValues(
        v,
        index,
        value,
        source,
        sourceIndex,
        'mutually_exclusive_answers',
      ),
  );
  const possibleAnswers =
    state?.possible_answers?.map((val) => val.answer) || [];
  const possibleAnswersCoincidences = possibleAnswers.filter((v, index) =>
    filterValues(v, index, value, source, sourceIndex, 'possible_answers'),
  );
  return (
    exclusiveAnswersValuesCoincidences.length === 0 &&
    possibleAnswersCoincidences.length === 0
  );
};

export const possible_answers_additional_payload = {
  name: 'possible_answers',
  interface: 'array',
  movable: true,
  title: 'Possible Answers',
  collapseable: {
    open: true,
    label: 'Collapse Answers',
  },
  isOrdered: true,
  addButtonLabel: 'Add answer',
  addButtonClassName: 'addSideEffect',
  items: [
    {
      label: 'Answer Choice',
      name: 'label',
      interface: 'rte',
      description: 'The answer displayed to the user on the screen.',
      inputStyle: 'ehp',
    },
    {
      label: 'Response Value',
      name: 'answer',
      interface: 'text',
      inputStyle: 'ehp',
      description: 'The value sent to the back end.',
      unique: true,
      validate: [
        (v, state, ...rest) => {
          const sourceIndex = rest[1].split('[')[1].split(']')[0];
          const uniqueValue = isUniqueValue(
            Number(v),
            state,
            sourceIndex,
            'possible_answers',
          );
          const finite = isFinite(Number(v));
          if (v !== undefined && String(v).length && !uniqueValue) {
            return 'Please enter a unique value';
          }
          if (v && !finite) {
            return 'Please enter a number';
          }

          return undefined;
        },
      ],
      required: true,
    },
    {
      name: 'additional_payload',
      interface: 'textarea',
      title: 'Additional Payload',
      inputStyle: 'ehp',
      validate: [validateJson],
    },
  ],
};

export const mutually_exclusive_answers_additional_payload = {
  name: 'mutually_exclusive_answers',
  interface: 'array',
  movable: true,
  title: 'Mutually Exclusive Answers',
  collapseable: {
    open: true,
    label: 'Collapse Answers',
  },
  isOrdered: true,
  addButtonLabel: 'Add answer',
  addButtonClassName: 'addSideEffect',
  items: [
    {
      label: 'Answer Choice',
      name: 'label',
      interface: 'rte',
      description: 'The answer displayed to the user on the screen.',
      inputStyle: 'ehp',
    },
    {
      label: 'Response Value',
      name: 'answer',
      interface: 'text',
      description: 'The value sent to the back end.',
      validate: [
        (v, state, ...rest) => {
          const sourceIndex = rest[1].split('[')[1].split(']')[0];
          const uniqueValue = isUniqueValue(
            Number(v),
            state,
            sourceIndex,
            'mutually_exclusive_answers',
          );
          const finite = isFinite(Number(v));
          if (v !== undefined && String(v).length && !uniqueValue) {
            return 'Please enter a unique value';
          }
          if (v && !finite) {
            return 'Please enter a number';
          }
          return undefined;
        },
      ],
      required: true,
      inputStyle: 'ehp',
    },
    {
      name: 'additional_payload',
      interface: 'textarea',
      title: 'Additional Payload',
      inputStyle: 'ehp',
      validate: [validateJson],
    },
  ],
};

export const checkbox2Validations = {
  name: 'answer_validations',
  interface: 'array',
  movable: false,
  title: 'Answer validations',
  collapseable: {
    open: true,
    className: 'validationsContainer',
    label: 'Collapse validations',
  },
  isOrdered: true,
  className: 'validationsContainer',
  limitLength: 1,
  deleteButtonIcon: <IconSubtract />,
  items: [
    {
      label: 'Min Answers Selected',
      name: 'min_value',
      interface: 'number',
      inputStyle: 'ehp',
    },
    {
      label: 'Max Answers Selected',
      name: 'max_value',
      interface: 'number',
      inputStyle: 'ehp',
    },
  ],
};

export const sliderValidations = {
  name: 'answer_validations',
  interface: 'array',
  movable: false,
  title: 'Answer validations',
  collapseable: {
    open: true,
    className: 'validationsContainer',
    label: 'Collapse validations',
  },
  isOrdered: true,
  className: 'validationsContainer',
  limitLength: 1,
  items: [
    {
      label: 'Min',
      name: 'min_value',
      interface: 'number',
      inputStyle: 'ehp',
      required: true,
    },
    {
      label: 'Max',
      name: 'max_value',
      interface: 'number',
      inputStyle: 'ehp',
      required: true,
    },
  ],
};

export const numberInputValidations = (components) => ({
  name: 'answer_validations',
  interface: 'array',
  movable: false,
  title: 'Answer validations',
  collapseable: {
    open: true,
    label: 'Collapse validations',
  },
  isOrdered: true,
  limitLength: 1,
  items: [
    {
      label: 'Min',
      name: 'min_source',
      interface: 'select',
      inputStyle: 'ehp',
      options: [
        { value: 'constant', label: 'Specific value' },
        { value: 'reference', label: 'Reference' },
      ],
    },
    {
      label: '',
      name: 'min_value_specific_value',
      interface: 'number',
      inputStyle: 'ehp',
      condition: (state, props) =>
        props.values.answer_validations[0].min_source === 'constant',
    },
    {
      label: '',
      name: 'min_value_reference',
      interface: 'select',
      inputStyle: 'ehp',
      options: [
        { value: 'survey_answer', label: 'Answer' },
        { value: 'user_data', label: 'User characteristics' },
      ],
      condition: (state, props) =>
        props.values.answer_validations[0].min_source === 'reference',
    },
    {
      label: '',
      name: 'min_component_id',
      interface: 'select',
      inputStyle: 'ehp',
      options: components
        .filter((component) =>
          [
            'number',
            'range',
            'select',
            'radio',
            'checkbox',
            'checkbox2',
          ].includes(component.interface),
        )
        .map((component) => ({
          value: component.component_id,
          label: component.name,
        })),
      condition: (state, props) =>
        props.values.answer_validations[0].min_value_reference ===
          'survey_answer' &&
        props.values.answer_validations[0].min_source === 'reference',
    },
    {
      label: '',
      name: 'min_user_data',
      interface: 'text',
      inputStyle: 'ehp',

      condition: (state, props) =>
        props.values.answer_validations[0].min_value_reference ===
          'user_data' &&
        props.values.answer_validations[0].min_source === 'reference',
    },
    {
      label: 'Ensure value is at least 0',
      name: 'forbid_negative_values',
      interface: 'checkbox',
      condition: (state, props) =>
        props.values.answer_validations[0].min_source === 'reference' &&
        props.values.answer_validations[0].min_value_reference ===
          'survey_answer',
    },
    {
      label: 'Max',
      name: 'max_source',
      interface: 'select',
      inputStyle: 'ehp',
      options: [
        { value: 'constant', label: 'Specific value' },
        { value: 'reference', label: 'Reference' },
      ],
    },
    {
      label: '',
      name: 'max_value_specific_value',
      interface: 'number',
      inputStyle: 'ehp',
      condition: (state, props) =>
        props.values.answer_validations[0].max_source === 'constant',
    },
    {
      label: '',
      name: 'max_value_reference',
      interface: 'select',
      inputStyle: 'ehp',
      options: [
        { value: 'survey_answer', label: 'Answer' },
        { value: 'user_data', label: 'User characteristics' },
      ],
      condition: (state, props) =>
        props.values.answer_validations[0].max_source === 'reference',
    },
    {
      label: '',
      name: 'max_component_id',
      interface: 'select',
      inputStyle: 'ehp',
      options: components
        .filter((component) =>
          [
            'number',
            'range',
            'select',
            'radio',
            'checkbox',
            'checkbox2',
          ].includes(component.interface),
        )
        .map((component) => ({
          value: component.component_id,
          label: component.name,
        })),
      condition: (state, props) =>
        props.values.answer_validations[0].max_value_reference ===
          'survey_answer' &&
        props.values.answer_validations[0].max_source === 'reference',
    },
    {
      label: '',
      name: 'max_user_data',
      interface: 'text',
      inputStyle: 'achievement',

      condition: (state, props) =>
        props.values.answer_validations[0].max_value_reference ===
          'user_data' &&
        props.values.answer_validations[0].max_source === 'reference',
    },
  ],
});

export const inchesDecimalPlaces = {
  interface: 'number',
  name: 'imperial_inches_decimal_places',
  label: 'Inches decimal places',
  condition: (_, { values }) => {
    if (values.unit_type === 'All' || values.unit_type === 'Imperial')
      return true;

    return false;
  },
};

export const heightInputValidations = () => {
  return [
    {
      interface: 'paragraph',
      name: 'answer_validations_text',
      label: '<strong>Answer Validations</strong>',
    },
    {
      interface: 'row',
      label: 'Metric',
      inputs: [
        [
          {
            interface: 'text',
            name: 'metric_min',
            label: 'Min',
            inputStyle: 'ehp',
            placeholder: 'Centimeters',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Metric')
          return true;

        return false;
      },
    },
    {
      interface: 'row',
      inputs: [
        [
          {
            interface: 'text',
            name: 'metric_max',
            label: 'Max',
            inputStyle: 'ehp',
            placeholder: 'Centimeters',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Metric')
          return true;

        return false;
      },
    },
    {
      interface: 'row',
      label: 'Imperial',
      inputs: [
        [
          {
            interface: 'text',
            name: 'imperial_feet_min',
            label: 'Feet Min',
            inputStyle: 'ehp',
            placeholder: 'Feet',
          },
          {
            interface: 'text',
            name: 'imperial_inches_min',
            label: 'Inches Min',
            inputStyle: 'ehp',
            placeholder: 'Inches',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Imperial')
          return true;

        return false;
      },
    },
    {
      interface: 'row',
      inputs: [
        [
          {
            interface: 'text',
            name: 'imperial_feet_max',
            label: 'Feet Max',
            inputStyle: 'ehp',
            placeholder: 'Feet',
          },
          {
            interface: 'text',
            name: 'imperial_inches_max',
            label: 'Inches Max',
            inputStyle: 'ehp',
            placeholder: 'Inches',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Imperial')
          return true;

        return false;
      },
    },
  ];
};

export const weightInputValidations = () => {
  return [
    {
      interface: 'paragraph',
      name: 'answer_validations_text',
      label: '<strong>Answer Validations</strong>',
    },
    {
      interface: 'row',
      label: 'Pounds',
      inputs: [
        [
          {
            interface: 'text',
            name: 'pounds_min',
            label: 'Min',
            inputStyle: 'ehp',
            placeholder: 'Min',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Pound')
          return true;

        return false;
      },
    },
    {
      interface: 'row',
      inputs: [
        [
          {
            interface: 'text',
            name: 'pounds_max',
            label: 'Max',
            inputStyle: 'ehp',
            placeholder: 'Max',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Pound')
          return true;

        return false;
      },
    },
    {
      interface: 'row',
      label: 'Stones',
      inputs: [
        [
          {
            interface: 'text',
            name: 'stones_min',
            label: 'Stones Min',
            inputStyle: 'ehp',
            placeholder: 'Stones Min',
          },
          {
            interface: 'text',
            name: 'stones_pounds_min',
            label: 'Pounds Min',
            inputStyle: 'ehp',
            placeholder: 'Pounds Min',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Stone')
          return true;

        return false;
      },
    },
    {
      interface: 'row',

      inputs: [
        [
          {
            interface: 'text',
            name: 'stones_max',
            label: 'Stones Max',
            inputStyle: 'ehp',
            placeholder: 'Stones Max',
          },
          {
            interface: 'text',
            name: 'stones_pounds_max',
            label: 'Pounds Max',
            inputStyle: 'ehp',
            placeholder: 'Pounds Max',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Stone')
          return true;

        return false;
      },
    },
    {
      interface: 'row',
      label: 'Kilograms',
      inputs: [
        [
          {
            interface: 'text',
            name: 'kg_min',
            label: 'Minimum',
            inputStyle: 'ehp',
            placeholder: 'Kilograms',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Metric')
          return true;

        return false;
      },
    },
    {
      interface: 'row',
      inputs: [
        [
          {
            interface: 'text',
            name: 'kg_max',
            label: 'Maximum',
            inputStyle: 'ehp',
            placeholder: 'Kilograms',
          },
        ],
      ],
      condition: (_, { values }) => {
        if (values.unit_type === 'All' || values.unit_type === 'Metric')
          return true;

        return false;
      },
    },
  ];
};

export const dateInputValidations = (components) => ({
  name: 'answer_validations',
  interface: 'array',
  movable: false,
  title: 'Answer validations',
  collapseable: {
    open: true,
    label: 'Collapse validations',
  },
  isOrdered: true,
  limitLength: 1,
  items: [
    {
      label: 'Min',
      name: 'min_source',
      interface: 'select',
      inputStyle: 'achievement',
      options: [
        { value: 'constant', label: 'Specific value' },
        { value: 'reference', label: 'Reference' },
      ],
    },
    {
      label: '',
      name: 'min_value_specific_value',
      interface: 'date_picker',
      condition: (state, props) =>
        props.values.answer_validations[0].min_source === 'constant',
    },
    {
      label: '',
      name: 'min_value_reference',
      interface: 'select',
      inputStyle: 'achievement',
      className: 'specificValue',
      options: [
        { value: 'survey_answer', label: 'Answer' },
        { value: 'user_data', label: 'User characteristics' },
      ],
      condition: (state, props) =>
        props.values.answer_validations[0].min_source === 'reference',
    },
    {
      label: '',
      name: 'min_component_id',
      interface: 'select',
      inputStyle: 'achievement',
      options: components
        .filter((component) =>
          ['date', 'date_of_birth'].includes(component.interface),
        )
        .map((component) => ({
          value: component.component_id,
          label: component.name,
        })),
      condition: (state, props) =>
        props.values.answer_validations[0].min_value_reference ===
        'survey_answer',
    },
    {
      label: '',
      name: 'min_user_data',
      interface: 'text',
      inputStyle: 'achievement',

      condition: (state, props) =>
        props.values.answer_validations[0].min_value_reference === 'user_data',
    },
    {
      label: 'Max',
      name: 'max_source',
      interface: 'select',
      inputStyle: 'achievement',
      options: [
        { value: 'constant', label: 'Specific value' },
        { value: 'reference', label: 'Reference' },
      ],
    },
    {
      label: '',
      name: 'max_value_specific_value',
      interface: 'date_picker',
      inputStyle: 'achievement',
      condition: (state, props) =>
        props.values.answer_validations[0].max_source === 'constant',
    },
    {
      label: '',
      name: 'max_value_reference',
      interface: 'select',
      inputStyle: 'achievement',
      options: [
        { value: 'survey_answer', label: 'Answer' },
        { value: 'user_data', label: 'User characteristics' },
      ],
      condition: (state, props) =>
        props.values.answer_validations[0].max_source === 'reference',
    },
    {
      label: '',
      name: 'max_component_id',
      interface: 'select',
      inputStyle: 'achievement',
      options: components
        .filter((component) =>
          ['date', 'date_of_birth'].includes(component.interface),
        )
        .map((component) => ({
          value: component.component_id,
          label: component.name,
        })),
      condition: (state, props) =>
        props.values.answer_validations[0].max_value_reference ===
        'survey_answer',
    },
    {
      label: '',
      name: 'max_user_data',
      interface: 'text',
      inputStyle: 'achievement',
      condition: (state, props) =>
        props.values.answer_validations[0].max_value_reference === 'user_data',
    },
  ],
});

const other = [
  {
    title: 'Optional answers',
    label: 'Other',
    name: 'other',
    interface: 'checkbox',
  },
  {
    label: 'Label',
    name: 'other_label',
    interface: 'textinput',
    condition: (path, { values: { other } }) => other,
    parse: (v) => v.trimLeft(),
  },
];

const prefer_not_to_answer = {
  label: 'Prefer not to answer',
  name: 'prefer_not_to_answer',
  interface: 'checkbox',
};

export const sensitiveDataFlag = {
  label: 'Hidden for Analysis',
  name: 'sensitive',
  interface: 'checkbox',
};

export const notHiddenForAnalysis = {
  ...sensitiveDataFlag,
  initialValue: false,
  readOnly: true,
};

export const hiddenForAnalysis = {
  ...sensitiveDataFlag,
  initialValue: true,
  readOnly: true,
};

const checkboxWithNone = [
  // {
  //   name: 'none_additional_payload',
  //   interface: 'textarea',
  //   title: '"None" Additional Payload',
  //   inputStyle: 'ehp',
  //   validate: [validateJson],
  // },
];

const checkbox2 = [
  ...other,
  {
    label: 'With None Option',
    name: 'with_none',
    interface: 'checkbox',
    inputStyle: 'sm_minimal',
  },
  {
    label: '"None" display label',
    name: 'noneLabel',
    description:
      'The label used for the "none of the above" selection in the list of possible choices.',
    interface: 'textinput',
    rows: 10,
    inputStyle: 'sm_minimal',
    condition: (path, { values }) => values.with_none,
  },
  {
    name: 'none_additional_payload',
    interface: 'textarea',
    title: '"None" Additional Payload',
    inputStyle: 'sm_minimal',
    validate: [validateJson],
    condition: (path, { values }) => values.with_none,
  },
];

const radio2 = [...other];

const date_of_birth = [
  {
    label: 'Format',
    name: 'dob_format',
    interface: 'select',
    options: ['YYYY-MM-DD', 'MM-DD-YYYY', 'YYYY-MM', 'YYYY'],
    initialValue: 'YYYY-MM-DD',
  },
  hiddenForAnalysis,
];

const gad7 = [notHiddenForAnalysis];
const phq9 = [
  {
    label: 'Modal Resources Title',
    name: 'modal_header',
    interface: 'text',
    required: false,
    inputStyle: 'sm_minimal',
    description: 'Leave blank to use default modal title.',
  },
  {
    label: 'Modal Resources Content',
    name: 'modal_content',
    interface: 'rte',
    required: false,
    inputStyle: 'sm_minimal',
    description: 'Leave blank to use default modal content.',
  },
  notHiddenForAnalysis,
];

const file_uploader = [
  {
    label: 'AWS Bucket',
    name: 'bucket',
    interface: 'textinput',
    inputStyle: 'sm_minimal',
    required: true,
  },
  {
    label: 'Maximum number of File(s) to upload',
    name: 'max_files',
    interface: 'numberinput',
    inputStyle: 'sm_minimal',
    required: true,
  },
];

export const CustomMaskTypes = [
  { value: 'length', label: 'A specified length' },
  { value: 'numeric', label: 'A number' },
];

const mask_custom = [
  {
    name: 'mask_custom_type',
    interface: 'select',
    inputStyle: 'muted',
    label: 'The answer should be',
    description: 'between',
    options: CustomMaskTypes,
    required: true,
  },
  {
    label: 'Min',
    name: 'mask_custom_min',
    interface: 'numberinput',
    inputStyle: 'sm_minimal',
    config: {
      minimum: 1,
    },
    required: true,
  },
  {
    label: 'Max',
    name: 'mask_custom_max',
    interface: 'numberinput',
    inputStyle: 'sm_minimal',
    config: {
      minimum: 1,
    },
    validate: [validateMaskCustomMax],
    required: true,
  },
  {
    name: 'mask_custom_restrict_input',
    interface: 'select',
    label: 'Restrict Input',
    required: true,
    possible_answers: ['alphanumeric', 'numeric', 'letter'],
    condition: (path, state) => state.values.mask_custom_type === 'length',
  },
  {
    label: `Prefix <small style="color: red">(Set this to empty and use 'Left Inline Label')</small>`,
    name: 'prefix',
    interface: 'text',
    inputStyle: 'sm_minimal',
  },
  {
    label: 'Left Inline Label',
    name: 'left_inline_label',
    interface: 'text',
    inputStyle: 'sm_minimal',
  },
  {
    name: 'custom_validation_message',
    interface: 'text',
    inputStyle: 'sm_minimal',
    label: 'Validation Message (Optional)',
    required: false,
  },
];

export const number = [
  {
    label: 'Decimal Place',
    name: 'decimal',
    interface: 'numberinput',
    inputStyle: 'sm_minimal',
    required: true,
  },
  {
    label: 'Unit',
    name: 'unit',
    interface: 'text',
    inputStyle: 'sm_minimal',
  },
];

const hours = [
  { value: 0, label: '12 am' },
  { value: 1, label: '1 am' },
  { value: 2, label: '2 am' },
  { value: 3, label: '3 am' },
  { value: 4, label: '4 am' },
  { value: 5, label: '5 am' },
  { value: 6, label: '6 am' },
  { value: 7, label: '7 am' },
  { value: 8, label: '8 am' },
  { value: 9, label: '9 am' },
  { value: 10, label: '10 am' },
  { value: 11, label: '11 am' },
  { value: 12, label: '12 pm' },
  { value: 13, label: '1 pm' },
  { value: 14, label: '2 pm' },
  { value: 15, label: '3 pm' },
  { value: 16, label: '4 pm' },
  { value: 17, label: '5 pm' },
  { value: 18, label: '6 pm' },
  { value: 19, label: '7 pm' },
  { value: 20, label: '8 pm' },
  { value: 21, label: '9 pm' },
  { value: 22, label: '10 pm' },
  { value: 23, label: '11 pm' },
];

const datetime = [
  {
    title: 'Date',
    name: 'datetimepicker_disable_date',
    interface: 'checkbox',
    label: 'Disable (time only)',
  },
  {
    name: 'datetimepicker_disable_future',
    interface: 'checkbox',
    label: 'Disable future dates',
  },
  {
    name: 'datetimepicker_date_range',
    interface: 'numberinput',
    label: 'Disable dates before',
    inputStyle: 'sm_minimal',
  },
  {
    name: 'datetimepicker_date_range_units',
    interface: 'select',
    inputStyle: 'muted',
    options: ['days', 'weeks'],
  },
  {
    label: 'Placeholder Label',
    name: 'date_placeholder',
    interface: 'text',
    required: false,
    inputStyle: 'sm_minimal',
  },
  {
    title: 'Time',
    name: 'datetimepicker_disable_time',
    interface: 'checkbox',
    label: 'Disable (date only)',
  },
  {
    name: 'datetimepicker_hours_start',
    label: 'start',
    interface: 'select',
    inputStyle: 'muted',
    options: hours,
  },
  {
    name: 'datetimepicker_hours_end',
    label: 'end',
    interface: 'select',
    inputStyle: 'muted',
    options: hours,
  },
  {
    label: 'Placeholder Label',
    name: 'time_placeholder',
    interface: 'text',
    required: false,
    inputStyle: 'sm_minimal',
  },
];

const select_number_range = [
  {
    label: 'Max',
    name: 'max',
    interface: 'numberinput',
    inputStyle: 'sm_minimal',
    validate: [ValidateMaxNumber],
    required: true,
  },
  {
    label: 'Min',
    name: 'min',
    interface: 'numberinput',
    inputStyle: 'sm_minimal',
    required: true,
  },
  {
    label: 'Placeholder Label',
    name: 'default_select_value',
    interface: 'text',
    required: false,
    inputStyle: 'sm_minimal',
  },
  {
    label: 'Sort Ascending',
    name: 'sort_ascending',
    interface: 'checkbox',
    inputStyle: 'muted',
  },
];

const address = [
  {
    label: 'Disable PO Boxes',
    name: 'disable_po_box',
    interface: 'checkbox',
    labelStyle: 'dark',
  },
  notHiddenForAnalysis,
];

const simple_matrix = (data) => {
  const answerOptions = data.answer_labels.map(({ index, answer }) => ({
    label: sanitizeHtml(answer, {
      allowedTags: [],
      allowedAttributes: {},
    }),
    value: `${index}`,
  }));

  return [
    {
      label: 'Matrix Label',
      name: 'question_label',
      inputStyle: 'sm_minimal',
      interface: 'rte',
    },
    {
      title: 'Answers (columns)',
      name: 'answer_labels',
      interface: 'array',
      movable: true,
      required: true,
      isOrdered: true,
      items: [
        {
          label: 'Answer',
          name: 'answer',
          interface: 'rte',
          required: true,
          inputStyle: 'sm_minimal',
        },
      ],
    },
    {
      title: 'Questions (rows)',
      description:
        'If matrix label and question labels are empty, the first column will be hidden',
      name: 'questions',
      interface: 'array',
      required: true,
      movable: true,
      isOrdered: true,
      items: [
        {
          label: 'Question',
          name: 'question',
          interface: 'rte',
          inputStyle: 'sm_minimal',
        },
        sensitiveDataFlag,
        {
          title: 'Additional Payload',
          name: 'question_additional_payload',
          interface: 'array',
          className: 'additionalPayloads',
          addButtonLabel: '+ add payload',
          items: [
            {
              label: 'Answer',
              name: 'answer',
              interface: 'select',
              options: answerOptions,
              required: true,
            },
            {
              title: 'JSON Payload',
              name: 'additional_payload',
              interface: 'textarea',
              inputStyle: 'sm_minimal',
              validate: [validateJson],
              required: true,
            },
          ],
        },
      ],
    },
  ];
};

const gender = [];

const sex = [other[0], prefer_not_to_answer, hiddenForAnalysis];

const typing_test = [
  {
    label: 'Start Prompt Title',
    name: 'start_prompt_title',
    interface: 'textinput',
    required: true,
    inputStyle: 'sm_minimal',
  },
  {
    label: 'Start Prompt',
    name: 'start_prompt',
    interface: 'rte',
    required: true,
  },
  {
    label: 'Continue Button Text',
    name: 'continue_button',
    interface: 'textinput',
    inputStyle: 'sm_minimal',
  },
  {
    label: 'Typing Prompt Title',
    name: 'typing_prompt_title',
    interface: 'textinput',
    required: true,
    inputStyle: 'sm_minimal',
  },
  {
    label: 'Typing Prompt Instruction',
    name: 'typing_prompt_instruction',
    interface: 'rte',
    inputStyle: 'sm_minimal',
  },
  {
    label: 'Allow free text',
    name: 'allow_free_text',
    interface: 'checkbox',
    inputStyle: 'sm_minimal',
  },
  {
    label: 'Typing Prompt',
    name: 'typing_prompt',
    interface: 'textinput',
    required: true,
    inputStyle: 'sm_minimal',
    condition: (path, state) => state.values.allow_free_text !== true,
  },
  {
    label: 'Background Text Color',
    name: 'background_text_color',
    interface: 'color',
    required: true,
    inputStyle: 'sm_minimal',
    initialValue: '#666666',
  },
  {
    label: 'Overlay Text Color',
    name: 'overlay_text_color',
    interface: 'color',
    required: true,
    inputStyle: 'sm_minimal',
    initialValue: '#80CF96',
  },
  {
    label: 'Timer (in minutes)',
    name: 'timer',
    interface: 'number',
    required: true,
    decimal: 0,
    inputStyle: 'sm_minimal',
  },
  {
    label: 'Minimum time (in minutes)',
    name: 'minimum_time',
    interface: 'number',
    decimal: 0,
    inputStyle: 'sm_minimal',
  },
];

const age = [notHiddenForAnalysis];
const english = [notHiddenForAnalysis];
const ethnicity = [notHiddenForAnalysis];
const pregnancy = [notHiddenForAnalysis];
const us_resident = [notHiddenForAnalysis];
const language = [
  {
    label: 'Select language',
    name: 'selected_language',
    interface: 'select',
    options: ['English', 'French', 'German', 'Italian', 'Spanish'],
  },
];
const country_resident = [
  {
    label: 'Select country',
    name: 'selected_country',
    interface: 'select',
    options: ['US', 'UK', 'Germany', 'France', 'Canada', 'Italy', 'Spain'],
  },
];

const height_intl = [
  {
    label: 'Unit Type',
    name: 'unit_type',
    interface: 'select',
    inputStyle: 'ehp',
    options: ['All', 'Imperial', 'Metric'],
    required: true,
  },
];

const weight_intl = [
  {
    label: 'Unit Type',
    name: 'unit_type',
    interface: 'select',
    inputStyle: 'ehp',
    options: ['All', 'Pound', 'Stone', 'Metric'],
    required: true,
  },
];

const phone_intl = [
  {
    label: 'Default Country',
    name: 'default_country',
    interface: 'select',
    options: generateCountryList(),
  },
];

const validatedText = [
  {
    label: 'List',
    name: 'list',
    interface: 'textinput',
    required: true,
  },
  {
    label: 'List Version',
    name: 'list_version',
    interface: 'number',
    required: true,
  },
  {
    label: 'Minimum length of entry',
    name: 'mask_custom_min',
    interface: 'number',
  },
  {
    label: 'Maximum length of entry',
    name: 'mask_custom_max',
    interface: 'number',
  },
  {
    label: 'Maximum number of attempts',
    name: 'max_attempts',
    interface: 'number',
    required: true,
  },
  {
    label: 'Error message before retries are exhausted',
    name: 'error_message',
    interface: 'textinput',
    required: true,
    initialValue: 'Validation attempt unsuccessful. Please try again.',
  },
  {
    label: 'Error message when retries are exhausted',
    name: 'attempts_exhausted_message',
    interface: 'textinput',
    required: true,
    initialValue:
      'You have attempted the maximum amount of validations attempts, Please contact the Study Support team.',
  },
];

const text = [
  {
    label: 'Text only',
    name: 'textOnlyValidation',
    interface: 'checkbox',
    required: false,
  },
];

const selectadvanced = [
  {
    label: 'Allow selecting multiple values',
    name: 'is_multi_select',
    interface: 'checkbox',
    labelStyle: 'dark',
  },
  {
    interface: 'textarea_file',
    maxSize: 10485760,
    validFileTypes: [
      {
        extension: 'csv',
        mime: 'text/csv',
      },
    ],
    label: 'Import CSV List of Options',
    name: 'possible_answers', // Note: This has the same name as the previous input so that their values are synced.
    description: 'Optional import of CSV list of options.',
    useObjectValue: false,
    dataTransformation: transformCsvAdditionalPayload,
    key: 'possible_answers_from_csv',
  },
];

const marks_range_slider = {
  name: 'marks_range_slider',
  interface: 'array',
  title: 'Marks on range slider',
  collapseable: {
    open: true,
    label: 'Collapse Marks',
  },
  items: [
    {
      label: 'Position',
      name: 'position',
      interface: 'number',
      required: true,
      inputStyle: 'sm_minimal',
    },
    {
      label: 'Label',
      name: 'label',
      interface: 'rte',
      inputStyle: 'sm_minimal',
      required: true,
    },
  ],
};

const range_slider = [
  {
    label: 'Question',
    name: 'slider_question',
    interface: 'rte',
    inputStyle: 'sm_minimal',
    required: true,
  },
  {
    label: 'Slider value text',
    name: 'slider_value_text',
    interface: 'rte',
    inputStyle: 'sm_minimal',
    required: true,
  },
  {
    label: 'Minimum length of entry',
    name: 'slider_min',
    interface: 'number',
    required: true,
  },
  {
    label: 'Minimum length of entry label',
    name: 'slider_min_label',
    interface: 'rte',
  },
  {
    label: 'Maximum length of entry',
    name: 'slider_max',
    interface: 'number',
    required: true,
  },
  {
    label: 'Maximum length of entry label',
    name: 'slider_max_label',
    interface: 'rte',
  },
  {
    label: 'Steps',
    name: 'slider_steps',
    interface: 'number',
    validate: [sliderPositiveSteps],
  },
  marks_range_slider,
  {
    label: 'Horizontal Orientation',
    name: 'slider_horizontal_orientation',
    interface: 'checkbox',
    required: false,
  },
  {
    label: 'Custom Height (px)',
    name: 'slider_custom_height',
    interface: 'number',
    initialValue: 400,
    required: true,
  },
];

const standardComponents = {
  address,
  age,
  date_of_birth,
  english,
  language,
  ethnicity,
  gender,
  sex,
  pregnancy,
  us_resident,
  country_resident,
  gad7,
  phq9,
  simple_matrix,
  typing_test,
};

export const schema_dictionary = {
  ...standardComponents,
  checkbox: checkboxWithNone,
  checkboxWithNone,
  radio: radio2,
  checkbox2,
  radio2,
  file_uploader,
  mask_custom,
  datetime,
  height_intl,
  weight_intl,
  phone_intl,
  'select-number-range': select_number_range,
  validatedText,
  selectadvanced,
  text,
  range_slider,
};

export const studyDataTrigger = {
  name: 'side_effects',
  interface: 'array',
  movable: false,
  title: 'Study data trigger',
  labelTooltip: {
    content:
      'When the parent component is submitted, it will trigger the configured action(s)',
  },
  collapseable: {
    open: true,
    className: 'sideEffectsContainer',
    label: 'Collapse actions',
  },
  isOrdered: true,
  addButtonLabel: 'Add action',
  className: 'sideEffectsContainer',
  addButtonClassName: 'addSideEffect',
  deleteButtonIcon: <IconSubtract />,
  items: [
    {
      label: 'Action type',
      name: 'type',
      interface: 'select',
      inputStyle: 'ehp',
      initialValue: 'Select a type',
      options: [{ value: 'event_emission', label: 'Event' }],
      required: true,
    },
    {
      label: 'Event name',
      name: 'params.slug',
      interface: 'textinput',
      inputStyle: 'ehp',
      required: true,
      parse: (value) => parseSlug(value),
    },
    {
      label: 'Event status',
      name: 'params.status',
      interface: 'select',
      options: event_statuses,
      inputStyle: 'ehp',
      required: true,
    },
  ],
};

import React from 'react';

export default (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
    >
      <title>icons/tile-types/participant_limit copy</title>
      <desc>Created with Sketch.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect
          fillOpacity="0"
          fill="#FFFFFF"
          x="0"
          y="0"
          width="24"
          height="24"
        />
        <g transform="translate(16.000000, 4.000000)" fill="#000000">
          <rect x="0" y="0" width="7" height="1" rx="0.5" />
          <g transform="translate(1.000000, 1.000000)" fillRule="nonzero">
            <rect x="2" y="2" width="1" height="12" rx="0.5" />
            <polygon points="2.5 0 5 3 0 3" />
          </g>
        </g>
        <g
          transform="translate(1.000000, 7.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <g transform="translate(4.732143, 0.000000)">
            <path d="M4.73214286,1.89285714 C4.73214286,2.93784903 3.8842776,3.78571429 2.83928571,3.78571429 C1.79362541,3.78571429 0.946428571,2.93812722 0.946428571,1.89285714 C0.946428571,0.847587067 1.79362541,0 2.83928571,0 C3.8842776,0 4.73214286,0.847865252 4.73214286,1.89285714 Z" />
            <path d="M0.000194286981,6.41981724 C6.50770588e-05,6.41592949 0,6.41202682 0,6.40811012 C0,4.95994658 1.2712029,3.78571429 2.83928571,3.78571429 C4.40681655,3.78571429 5.67857143,4.96018602 5.67857143,6.40811012 C5.67857143,6.41202682 5.67850635,6.41592949 5.67837714,6.41981724 C5.67850635,6.423705 5.67857143,6.42760766 5.67857143,6.43152437 L5.67857143,8.65569066 C5.67857143,8.86259198 5.49697264,9.03031864 5.27295918,9.03031864 L4.42321677,9.03031864 L4.05410947,12.4394346 C4.0333745,12.6309451 3.85889408,12.7767857 3.6505102,12.7767857 L2.02806122,12.7767857 C1.81967735,12.7767857 1.64519693,12.6309451 1.62446196,12.4394346 L1.25535466,9.03031864 L0.405612245,9.03031864 C0.181598788,9.03031864 6.06747019e-16,8.86259198 6.06747019e-16,8.65569066 L6.06747019e-16,6.43152437 C6.06747019e-16,6.42760766 6.50770588e-05,6.423705 0.000194286981,6.41981724 L0.000194286981,6.41981724 Z" />
          </g>
          <g transform="translate(0.000000, 0.946429)">
            <path d="M3.94345238,1.57738095 C3.94345238,2.44820753 3.236898,3.1547619 2.36607143,3.1547619 C1.49468784,3.1547619 0.788690476,2.44843935 0.788690476,1.57738095 C0.788690476,0.706322556 1.49468784,0 2.36607143,0 C3.236898,0 3.94345238,0.706554377 3.94345238,1.57738095 Z" />
            <path d="M0.000161905817,5.3498477 C5.42308824e-05,5.34660791 0,5.34335569 0,5.34009177 C0,4.13328882 1.05933575,3.1547619 2.36607143,3.1547619 C3.67234713,3.1547619 4.73214286,4.13348835 4.73214286,5.34009177 C4.73214286,5.34335569 4.73208863,5.34660791 4.73198095,5.3498477 C4.73208863,5.3530875 4.73214286,5.35633972 4.73214286,5.35960364 L4.73214286,7.21307555 C4.73214286,7.38549332 4.58081053,7.52526553 4.39413265,7.52526553 L3.68601397,7.52526553 L3.37842456,10.3661955 C3.36114542,10.5257876 3.21574507,10.6473214 3.04209184,10.6473214 L1.69005102,10.6473214 C1.51639779,10.6473214 1.37099744,10.5257876 1.3537183,10.3661955 L1.04612889,7.52526553 L0.338010204,7.52526553 C0.151332323,7.52526553 5.05622516e-16,7.38549332 5.05622516e-16,7.21307555 L5.05622516e-16,5.35960364 C5.05622516e-16,5.35633972 5.42308824e-05,5.3530875 0.000161905817,5.3498477 L0.000161905817,5.3498477 Z" />
          </g>
          <g transform="translate(10.410714, 0.946429)">
            <path d="M3.94345238,1.57738095 C3.94345238,2.44820753 3.236898,3.1547619 2.36607143,3.1547619 C1.49468784,3.1547619 0.788690476,2.44843935 0.788690476,1.57738095 C0.788690476,0.706322556 1.49468784,0 2.36607143,0 C3.236898,0 3.94345238,0.706554377 3.94345238,1.57738095 Z" />
            <path d="M0.000161905817,5.3498477 C5.42308824e-05,5.34660791 0,5.34335569 0,5.34009177 C0,4.13328882 1.05933575,3.1547619 2.36607143,3.1547619 C3.67234713,3.1547619 4.73214286,4.13348835 4.73214286,5.34009177 C4.73214286,5.34335569 4.73208863,5.34660791 4.73198095,5.3498477 C4.73208863,5.3530875 4.73214286,5.35633972 4.73214286,5.35960364 L4.73214286,7.21307555 C4.73214286,7.38549332 4.58081053,7.52526553 4.39413265,7.52526553 L3.68601397,7.52526553 L3.37842456,10.3661955 C3.36114542,10.5257876 3.21574507,10.6473214 3.04209184,10.6473214 L1.69005102,10.6473214 C1.51639779,10.6473214 1.37099744,10.5257876 1.3537183,10.3661955 L1.04612889,7.52526553 L0.338010204,7.52526553 C0.151332323,7.52526553 5.05622516e-16,7.38549332 5.05622516e-16,7.21307555 L5.05622516e-16,5.35960364 C5.05622516e-16,5.35633972 5.42308824e-05,5.3530875 0.000161905817,5.3498477 L0.000161905817,5.3498477 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

import { Included, QAOverridesData, RawQAOverrides } from 'src/api/qaOverrides';

export const transformRawData = (overrides: RawQAOverrides) => {
  const data = overrides.data.map((override) => {
    const prodValue = overrides.included.find(
      (prodValue) => prodValue.id === override.relationships.resource.data.id,
    );
    return {
      ...override,
      prodValue: prodValue,
    };
  });
  return data;
};

export const filterSideEffects = (
  sideEffects: Included[],
  currentOverrides: QAOverridesData[],
  overrideToEdit?: QAOverridesData,
) => {
  const index = sideEffects.findIndex(
    (effect) => effect.id === 'side_effect_params',
  );
  if (index !== -1) {
    sideEffects.splice(index, 1);
  }
  const availableSideEffect = sideEffects.filter(
    (effect) =>
      !currentOverrides.some(
        (currentOverride) =>
          currentOverride.relationships.resource.data.id === effect.id &&
          effect.id !== overrideToEdit?.relationships.resource.data.id,
      ),
  );
  return availableSideEffect;
};

import { format } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import stripHtml from '../../../../utils/stripHtml';
import Dropdown from '../../../dropdown';
import {
  Container,
  CustomToggle,
  Dot,
  Heading,
  Item,
  MenuButton,
  MenuItem,
  MenuItemWrapper,
  NoVersionsWrapper,
} from './components';

const VERSION_HISTORY_TITLE = 'Current Version';
const VERSION_HISTORY_BASE_VERSION_TITLE = 'Current version based on version';
const VERSION_HISTORY_PREVIEW_TITLE = 'Previewing version';
const VERSION_HISTORY_NO_VERSIONS_MESSAGE = 'No Versions to display';
const VERSION_HISTORY_ITEM_TITLE = 'Version:';
const VERSION_HISTORY_ITEM_DEFAULT_MESSAGE = 'No Changes Added';
const VERSION_HISTORY_ITEM_DROPDOWN_SEE_NOTES_LABEL = 'See Notes';
const VERSION_HISTORY_ITEM_DROPDOWN_COMPARE_VERSION_LABEL = 'See Changes';

const VERSION_HISTORY_ITEM_DROPDOWN_TOGGLE_ARIA_TITLE =
  'Toggle Menu for Version';
const VERSION_HISTORY_ITEM_DROPDOWN_REVERT_TITLE = 'Revert to Version';
const VERSION_HISTORY_ITEM_DROPDOWN_REVERT_LABEL = 'Revert to This Version';

export function VersionHistoryItem({
  version,
  graph,
  onRevert,
  canRevert,
  onSetPreview,
  onSetSemanticVersion,
  onClose,
  loading,
  previousId = null,
}) {
  const dropdownToggleRef = useRef();
  // when the user clicks on the div focus the button
  // so we can use css rule to highlight the div in one place.
  const handleFocusBtn = useCallback(() => {
    if (dropdownToggleRef.current) {
      dropdownToggleRef.current.focus();
    }
    onSetPreview(version.id);
    onSetSemanticVersion(version.semantic_version);
  }, [dropdownToggleRef, version, onSetPreview, onSetSemanticVersion]);

  return (
    <Item
      role="listitem"
      data-testid="item"
      onClick={handleFocusBtn}
      loading={loading}
    >
      <div className="item-heading">
        <span>
          &#9658; {VERSION_HISTORY_ITEM_TITLE} {version.semantic_version}
        </span>
        <Dropdown
          align="right"
          size="sm"
          toggle={
            <CustomToggle
              ref={dropdownToggleRef}
              // for screenreader on focus.
              title={`${VERSION_HISTORY_ITEM_DROPDOWN_TOGGLE_ARIA_TITLE} ${version.semantic_version}`}
            />
          }
        >
          {canRevert && (
            <MenuItemWrapper>
              <MenuButton
                onClick={() => onRevert(version)}
                title={`${VERSION_HISTORY_ITEM_DROPDOWN_REVERT_TITLE} ${version.semantic_version}`}
              >
                {VERSION_HISTORY_ITEM_DROPDOWN_REVERT_LABEL}
              </MenuButton>
            </MenuItemWrapper>
          )}
          <MenuItemWrapper>
            <MenuItem>
              <Link
                to={`/study/${graph.id}/versions/${version.id}`}
                onClick={onClose}
              >
                {VERSION_HISTORY_ITEM_DROPDOWN_SEE_NOTES_LABEL}
              </Link>
            </MenuItem>
          </MenuItemWrapper>
          {previousId && (
            <MenuItemWrapper>
              <MenuItem>
                <Link
                  to={`/study/${graph.id}/versions/${version.id}/compare/${previousId}`}
                  onClick={onClose}
                >
                  {VERSION_HISTORY_ITEM_DROPDOWN_COMPARE_VERSION_LABEL}
                </Link>
              </MenuItem>
            </MenuItemWrapper>
          )}
        </Dropdown>
      </div>
      <div className="item-body-wrapper">
        <Dot />
        <div className="item-body-content">
          <span>
            {format(new Date(version.created_at), 'yyyy/MM/dd hh:mm:ss aa')}
          </span>
          <span className="version-comments">
            {version?.title ? (
              `${stripHtml(version.title).substring(0, 25)}...`
            ) : (
              <span className="default-message">
                {VERSION_HISTORY_ITEM_DEFAULT_MESSAGE}
              </span>
            )}
          </span>
        </div>
      </div>
    </Item>
  );
}

export default function VersionHistory({
  graph,
  versions,
  onRevert,
  canRevert,
  onSetPreview,
  onClose,
  loading,
}) {
  const [currentPreviewId, setCurrentPreviewId] = useState(null);
  const [semanticVersion, setSemanticVersion] = useState(null);
  const handleSetPreview = (publishId) => {
    if (currentPreviewId !== publishId) {
      setCurrentPreviewId(publishId);
      onSetPreview(publishId);
    }
  };

  const handleSetSemanticVersion = (semanticId) => {
    setSemanticVersion(semanticId);
  };

  return (
    <Container>
      <Heading>
        {graph.content?.base_version_id
          ? currentPreviewId
            ? `${VERSION_HISTORY_PREVIEW_TITLE} ${semanticVersion}`
            : `${VERSION_HISTORY_BASE_VERSION_TITLE} ${graph.content?.base_version_id}`
          : VERSION_HISTORY_TITLE}
      </Heading>
      <section role="list">
        {Array.isArray(versions) ? (
          versions.map((version, index) => {
            // Need to set the previous version ID based on versions index. The ID
            // may not be sequential if publishes occur in the same environment
            // but a different study.
            let previousId = null;
            const prevVersion = index + 1;
            if (prevVersion <= versions.length - 1) {
              previousId = versions[prevVersion].id;
            }

            return (
              <VersionHistoryItem
                version={version}
                graph={graph}
                loading={loading}
                onClose={onClose}
                onRevert={onRevert}
                canRevert={canRevert}
                onSetPreview={handleSetPreview}
                onSetSemanticVersion={handleSetSemanticVersion}
                previousId={previousId}
                key={`version_history_item_${version.id}`}
              />
            );
          })
        ) : (
          <NoVersionsWrapper>
            {VERSION_HISTORY_NO_VERSIONS_MESSAGE}
          </NoVersionsWrapper>
        )}
      </section>
    </Container>
  );
}

import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

export const MilestoneTileSettings = (
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
      C.display_group_name(displayGroupNames),
      C.experience_attribute,
      {
        name: 'state_change',
        interface: 'select',
        label: 'New participant state to change to',
        options: [
          {
            value: 'screening_completed',
            label: 'screening_completed',
          },
          {
            value: 'enrollment_completed',
            label: 'enrollment_completed',
          },
          { value: 'study_completed', label: 'study_completed' },
          { value: 'timed_out', label: 'timed_out' },
          { value: 'withdrawn', label: 'withdrawn' },
          { value: 'disqualified', label: 'disqualified' },
        ],
        initialValue: { value: '', label: '' },
      },
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
      }),
    ],
  }),
});

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  displayGroupNames,
  dataForTile,
}) => {
  const {
    milestone: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content.experiences.${experience}`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.description,
        C.completion_message,
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(MilestoneTileSettings(experience, displayGroupNames));
  }

  return [schema];
};

import _ from 'lodash';
import { event_statuses } from '../../../../constants';

export default (props) => {
  // SHIPPING CONFIG - leave this formatting in for backwards compatibility
  if (
    props.content.shipping_config &&
    !_.isArray(props.content.shipping_config)
    // !isArray fixes a bug, i think the object is being mutated when a graph is saved,
    // and is becoming an array as a side effect
  ) {
    props.content.shipping_config = _.reduce(
      props.content.shipping_config,
      (acc, sheet_title, event_identifier) => {
        return [...acc, { event_identifier, sheet_title }];
      },
      [],
    );
  }

  // EVENT DELAY
  if (props.content.event_delay_config) {
    props.content.event_delay_config = _.reduce(
      props.content.event_delay_config,
      (acc, { ...rest }, node_slug) => [...acc, { node_slug, ...rest }],
      [],
    );
  }

  //DQ Criteria
  if (props.content.disqualification_criteria) {
    props.content.disqualification_criteria = _.reduce(
      props.content.disqualification_criteria,
      (acc, criterion) => {
        let attribute = criterion['attribute'];
        delete criterion['attribute'];
        let attributeIndex = _.findIndex(
          acc,
          (v, k, acc) => v['attribute'] === attribute,
        );

        if (attributeIndex !== -1) {
          acc[attributeIndex].disqualification_criteria.push({
            ...criterion,
          });
        } else {
          acc.push({
            attribute,
            disqualification_criteria: [{ ...criterion }],
          });
        }
        return acc;
      },
      [],
    );
  }

  // SITE MAPPING
  if (props.content.site_mapping) {
    props.content.site_mapping = _.reduce(
      props.content.site_mapping,
      (acc, { ...rest }, site_id) => [...acc, { site_id, ...rest }],
      [],
    );
  }

  // STUDY BRANDING
  if (props.brand) {
    // There is no need to unset the higher level brand obj
    // it will be reused during the outgoing payload formatting.
    props.content[`brand_name`] = props.brand.brand_name;
  }

  // SETTINGS

  // LANGUAGES
  // mapping the secondary_locales to an array of objects e.g: [{secondary_locale_value: "en-GB"}, ....]
  //`secondary_locale_value` is from `study-manager/src/routes/edit/forms/graph/languages.js`
  if (props?.secondary_locales) {
    props.secondary_locales = props.secondary_locales.reduce(
      (acc, current_item) => {
        acc.push({ secondary_locale_value: current_item });
        return acc;
      },
      [],
    );
  }

  // Billboard Branding
  if (props.authentication_provider) {
    props.content.authentication_provider = props.authentication_provider;
  }

  // Convert expiration from seconds to hours
  if (props?.content.email_update_link_expiration) {
    props.content.email_update_link_expiration =
      Number(props.content.email_update_link_expiration) / 3600;
  }

  if (
    props?.content.participant_explorer_event_slugs &&
    props.content.participant_explorer_event_slugs.length
  ) {
    const { participant_explorer_event_slugs: p } = props.content;
    props.content.participant_explorer_event_slugs = p.map((val) => {
      if (val) {
        let _slug = val;
        let _status = '';
        const parts = val.split('_');
        const status = parts[parts.length - 1];
        const isStatusValid = event_statuses.includes(status);
        if (isStatusValid) {
          _slug = parts.slice(0, -1).join('_');
          _status = status;
        }
        return {
          slug: _slug,
          status: _status,
        };
      }
      return {
        slug: '',
        status: '',
      };
    });
  }

  if (props.content?.participant_default_inactivity_timeout) {
    // Convert from seconds to hours
    const hours = Math.floor(
      props.content?.participant_default_inactivity_timeout / 3600,
    );
    const minutes = Math.floor(
      (props.content?.participant_default_inactivity_timeout % 3600) / 60,
    );
    props.content.participant_default_inactivity_timeout = `${hours}:${minutes}`;
  }

  if (props.content?.participant_max_session_length) {
    // Convert from seconds to hours
    const hours = Math.floor(
      props.content?.participant_max_session_length / 3600,
    );
    const minutes = Math.floor(
      (props.content?.participant_max_session_length % 3600) / 60,
    );
    props.content.participant_max_session_length = `${hours}:${minutes}`;
  }

  if ([undefined, null].includes(props.content.self_withdrawal_allowed)) {
    props.content.self_withdrawal_allowed = true;
  }

  return props;
};

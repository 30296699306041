import * as C from '../common';

import { default_experience_key } from '../../../../constants';
import { stringTimeToSeconds } from '../utils';

const AWS_COGNITO_PROVIDER = 'AWS Cognito';

export default ({
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
}) => {
  return [
    [
      {
        name: 'content',
        input: [
          {
            name: 'internal_study_settings',
            label: 'Internal Project Settings',
            interface: 'settings_heading',
          },
          {
            name: 'title',
            label: 'Internal Title',
            required: true,
            interface: 'text_horizontal',
            description:
              'Internal for the research manager only. This appears in the list of projects, in the Research Manager navigation, and in internal reports. It does not show up anywhere that participants can see it.',
          },
          {
            name: 'description',
            label: 'Internal Description',
            interface: 'textarea_horizontal',
            description:
              'Internal for the research manager only. This is a short description of the project.',
          },
          {
            name: 'slug',
            label: 'URL Suffix',
            interface: 'text_horizontal',
            required: true,
            description:
              'This uniquely identifies the project. It is used both for internal identification where uniqueness is important AND it is displayed to the participant in a variety of contexts. URL Suffix will appear in the browser location bar, in advertising links, in email addresses, and in some external tracking information. URL Suffix should not reveal information you do not want known by the participant during the project.',
            parse: C.parseSlug,
          },
          {
            name: 'preview_token',
            label: 'Preview Token',
            description:
              'Token to give access to the project before it goes Live while the project is in draft and QA status.',
            interface: 'text_horizontal',
          },
          {
            name: 'external_services',
            label: 'External Services',
            interface: 'settings_heading',
          },
          {
            name: 'keywords',
            label: 'SEO Keywords',
            description:
              'Recommended not to change these. These are the keywords used for finding this project in search engines.',
            interface: 'text_horizontal',
          },
          {
            name: 'gtm_id',
            label: 'Google Tag Manager ID (Google Analytics)',
            interface: 'text_horizontal',
          },
          {
            name: 'gtm_id_cookie_banner',
            label: 'Google Tag Manager ID (Cookie banner)',
            interface: 'text_horizontal',
          },
          {
            name: 'communications',
            label: 'Communications',
            interface: 'settings_heading',
          },
          {
            name: 'contact_email',
            label: 'From Email Address',
            description:
              'This appears as the “Sent From”, and “Reply To” parameters in participant communications',
            interface: 'text_horizontal',
          },
          {
            name: 'contact_modal_heading',
            label: 'Contact Modal Heading',
            interface: 'text_horizontal',
            description: 'This appears as the heading in the contact modal.',
          },
          {
            name: 'contact_modal_message',
            label: 'Contact Modal Message',
            interface: 'textarea_horizontal',
            description: 'This is the message displayed in the contact modal.',
          },
          {
            name: 'contact_modal_phone_number',
            label: 'Contact Modal Phone Number',
            interface: 'toggle_horizontal',
            description:
              '(NOT WORKING YET) This sets wether a contact phone number is displayed in the contact modal.',
          },
          {
            name: 'verify_phone_numbers',
            label: 'Validate Phone Number',
            interface: 'toggle_horizontal',
            description:
              "If this setting is turned on, any phone number collected via the 'tel' or 'tel international' component will go through a verification process with OTP",
          },
          {
            name: 'contact_phone_number',
            label: 'Contact phone number',
            description:
              'This is the project phone contact number for Participants.',
            mask: '111-111-1111',
            interface: 'mask_horizontal',
          },
          {
            name: 'outbound_phone_number',
            label: 'Outbound phone number',
            description:
              'This number is used as the “from” number for all automated participant communications (SMS tiles, bulk SMS, etc)',
            mask: '111-111-1111',
            interface: 'mask_horizontal',
          },
          {
            name: 'contact_preferred_method',
            label: 'Preferred Contact Method',
            description: 'Preferred contact method for contact form',
            interface: 'select_horizontal',
            possible_answers: ['Email', 'Phone'],
          },
          {
            name: 'standalone_contributions',
            label: 'Standalone Contributions',
            interface: 'settings_heading',
          },
          {
            name: 'is_program',
            label: 'It is a program with standalone contributions',
            interface: 'toggle_horizontal',
            description:
              'Appropriate for when the program does not need a landing page or dashboard associated. Participants must reach these contributions from an Achievement offer.',
          },
          {
            name: 'address_settings_heading',
            label: 'Address Settings',
            interface: 'settings_heading',
          },
          {
            name: 'max_identical_addresses',
            label:
              'Set maximum number of allowed participants per single household address. This is an anti-abuse setting.',
            interface: 'number_horizontal',
          },
          {
            label: 'Authentication Providers',
            interface: 'settings_heading',
            name: 'auth_heading',
          },
          {
            name: 'authentication_provider',
            label: 'Authentication Provider',
            interface: 'select_horizontal',

            possible_answers: [
              { label: AWS_COGNITO_PROVIDER, value: AWS_COGNITO_PROVIDER },
            ],
            description:
              'Select an Authentication Provider and identity provider(s) for this study',
          },
          {
            name: 'identity_providers',
            label: 'Identity Provider',
            condition: (_, { values }) =>
              values.content?.authentication_provider === AWS_COGNITO_PROVIDER,
            interface: 'selectadvanced',
            containerProps: {
              style: {
                width: '50%',
              },
            },
            is_multi_select: true,
            possible_answers: [{ label: 'Local Login', value: 'COGNITO' }],
          },
          {
            label: 'Customer Service Provider',
            interface: 'settings_heading',
            name: 'customer_service_provider_heading',
          },
          {
            name: 'customer_service_provider',
            label: 'Customer Service Provider',
            interface: 'select_horizontal',
            defaultValue: 'Zendesk',
            possible_answers: [
              { label: 'PPMI Support', value: 'Freshdesk' },
              { label: 'Off', value: 'Off' },
            ],
          },
          {
            name: 'zendesk_api_key',
            label: 'Zendesk API Key',
            interface: 'text_horizontal',
            placeholder: 'Example: abcdef01-2345-6789-0abc-def123456789',
            validate: [C.validateUuid],
            parse: C.parseZendeskSnippet,
            condition: (path, { values }) => {
              const provider = values.content?.customer_service_provider;
              return !provider || provider === 'Zendesk';
            },
          },
          {
            label: 'Update Email Settings',
            interface: 'settings_heading',
            name: 'update_email_settings_header',
          },
          {
            name: 'email_update_template',
            label: 'Sendgrid Email Template ID',
            interface: 'text_horizontal',
            description:
              'Sendgrid ID for email template used for requesting update/verify email address.',
          },
          {
            name: 'email_update_link_expiration',
            label: 'Email Update Link Expiration (hours)',
            interface: 'number_horizontal',
            description: 'The time limit for the link to be active.',
          },
          {
            label: 'reCaptcha Settings',
            interface: 'settings_heading',
            name: 'recaptcha_settings_header',
          },
          {
            name: 'recaptcha_version',
            label: 'reCaptcha Support',
            interface: 'select_horizontal',
            defaultValue: 'Off',
            possible_answers: ['Enterprise Checkbox', 'Enterprise Score Based'],
            description:
              'The mode to use for reCaptcha Enterprise. The "checkbox" mode has users select matching image tiles, whereas the "score" mode scores users transparently based on their behavior.',
          },
          {
            name: 'participant_settings_heading',
            label: 'Participant Settings',
            interface: 'settings_heading',
          },
          {
            name: 'require_enrollment_email',
            label: 'Require participant email address',
            required: false,
            interface: 'checkbox',
            defaultValue: true,
          },
          {
            name: 'require_enrollment_phone',
            label: 'Require participant phone number',
            required: false,
            interface: 'checkbox',
            defaultValue: false,
          },
          {
            name: 'participant_settings_security_heading',
            label: 'Security',
            interface: 'settings_heading',
          },
          {
            name: 'participant_default_inactivity_timeout',
            label: 'Session Timeout for Inactivity',
            interface: 'text_horizontal',
            type: 'time',
            description:
              'The default amount of inactive time (in hours and minutes) after which participants will be logged out out by default. This can be overridden per participant by a user preference.',
            validate: [
              (value) => {
                if (value) {
                  const seconds = stringTimeToSeconds(value);
                  if (seconds < 300) {
                    return 'Inactivity Timeout must be greater than 5 minutes';
                  }
                  if (seconds > 86400) {
                    return 'Inactivity Timeout must be less than 24 hours';
                  }
                }
                return undefined;
              },
            ],
          },
          {
            name: 'participant_max_session_length',
            label: 'Maximum Session Length',
            interface: 'text_horizontal',
            type: 'time',
            description:
              'The amount of time (in hours and minutes) after which participants will be logged out even if they remain active',
            validate: [
              (value, state) => {
                if (value?.length > 0) {
                  const seconds = stringTimeToSeconds(value);
                  if (seconds > 31536000) {
                    return 'Maximum Session Length must be less than 365 days';
                  }
                  if (state.content.participant_default_inactivity_timeout) {
                    const timeoutSeconds = stringTimeToSeconds(
                      state.content.participant_default_inactivity_timeout,
                    );
                    if (seconds < timeoutSeconds) {
                      return 'Maximum Session Length must be greater or equal than Inactivity Timeout';
                    }
                  } else {
                    return 'Inactivity Timeout must be set, maximum Session Length must be greater or equal than Inactivity Timeout';
                  }
                }
                return undefined;
              },
            ],
          },
          {
            name: 'logout_redirect_url',
            label: 'Participant Logout URL',
            interface: 'text_horizontal',
            description:
              'The URL the participant will be redirected to after being logged out. Defaults to the log in page',
          },
          {
            description: 'Whether or not the participant should see a pop-up window shortly before their session expires. Defaults to true',
            label: 'Display Session Expiry Modal (toggle)',
            name: 'display_expiration_modal',
            interface: 'toggle_horizontal',
          }
        ],
      },
    ],
  ];
};

import { triageV3 } from './triage';

export type RawQAOverrides = {
  data: QAOverridesData[];
  meta: Meta;
  included: Included[];
};

export type Included = {
  type: string;
  id: string;
  attributes: QAvalue;
};

type Meta = {
  potential_overrides: Data[];
};

export type QAOverridesData = {
  type: string;
  id?: string;
  attributes: Attributes;
  relationships: Relationships;
};

export type Relationships = {
  resource: Resource;
  schema: Resource;
};

type Resource = {
  data: Data;
};

type Data = {
  type: string;
  id: string;
};

export type Attributes = {
  description: string;
  qa_value: QAvalue;
};

type QAvalue = {
  type: string;
  params: Params;
};

export type Params = {
  slug: string;
  status: string;
};

export const QAOverridesService = {
  save: ({
    graphId,
    data,
  }: {
    graphId: string | number;
    data: { data: QAOverridesData };
  }) => {
    return triageV3
      .post<RawQAOverrides>(`graphs/${graphId}/qa_overrides`, data)
      .then(({ data }) => data);
  },

  getAll: ({ graphId }: { graphId: string | number }) => {
    return triageV3
      .get<RawQAOverrides>(`graphs/${graphId}/qa_overrides`)
      .then(({ data }) => data);
  },
  update: ({
    graphId,
    data,
  }: {
    graphId: string | number;
    data: { data: QAOverridesData };
  }) => {
    return triageV3
      .put<RawQAOverrides>(
        `graphs/${graphId}/qa_overrides/${data.data.id}`,
        data,
      )
      .then(({ data }) => data);
  },
  delete: ({
    graphId,
    overrideId,
  }: {
    graphId: string | number;
    overrideId: string | number;
  }) => {
    return triageV3
      .delete(`graphs/${graphId}/qa_overrides/${overrideId}`)
      .then(({ data }) => data);
  },
};

"use strict";

require("core-js/modules/es.array.for-each.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.find-index.js");
require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.slice.js");
var _lodash = require("lodash");
var pivot = function pivot(rows, columns) {
  var columnNameList = columns.map(function (column) {
    return column['property'];
  });

  // get sibling set for the first row, first column
  // pass that sibling set to a recursive function that sets siblings for the next column
  getSiblingSet(rows, columnNameList, 0);
};
var getSiblingSet = function getSiblingSet(row_subset, columns, columnIndex) {
  var columnName = columns[columnIndex];
  var lastRow = {};
  var firstSibling = row_subset[0];
  var siblingCount = 1;
  var hiddenVar = "_".concat(columnName, "_hide");
  var siblingVar = "_".concat(columnName, "_siblings");
  (0, _lodash.forEach)(row_subset, function (row, row_index) {
    if (!row[columnName]) {
      row[columnName] = '';
    }
    if (row[columnName] === lastRow[columnName]) {
      siblingCount = siblingCount + 1;
      row[hiddenVar] = true;
    } else if (row_index > 0) {
      firstSibling[siblingVar] = siblingCount;
      if (columnIndex < columns.length) {
        getSiblingSet(row_subset.slice((0, _lodash.findIndex)(row_subset, firstSibling), row_index), columns, columnIndex + 1);
      }
      firstSibling = row;
      siblingCount = 1;
    }
    if (row_index === row_subset.length - 1) {
      // if we've gotten to the end of this list of rows
      firstSibling[siblingVar] = siblingCount;
      if (columnIndex < columns.length) {
        getSiblingSet(row_subset.slice((0, _lodash.findIndex)(row_subset, firstSibling)), columns, columnIndex + 1);
      }
    } else {
      lastRow = row;
    }
  });
};
var _default = exports["default"] = pivot;
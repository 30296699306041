//@ts-nocheck
import React, { PureComponent } from 'react';
import { marked } from 'marked';
import styled from 'styled-components';
import { connect } from 'react-redux';
import axios from 'axios';
import FroalaEditor from 'react-froala-wysiwyg';
import _ from 'lodash';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'font-awesome/css/font-awesome.css';
import { constructFieldClassname } from '@evidation/curious-panda';
import { InputLabel } from '@evidation/form-elements';
import manualStringTransformations, {
  decodeHtml,
} from './manualStringTransformations';
import { fileGetUrl, imageGetUrl } from '../../../api';
import { logError } from '../../../utils/errorLogger';
import Url from 'url-parse';
import { toastr } from 'react-redux-toastr';
import { e2eTestId } from '../../../utils/e2eTesting';
import HorizontalTextarea from '../horizontal_textarea';
import { InputStyles } from '@evidation/ui';

const FroalaWrapper = styled.div`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  ${InputStyles}
  .fr-box {
    &.fr-fullscreen {
      background: #fff;
      padding: 10px;
      border: 10px solid gray;
    }
  }
  min-height: 40px;
  height: auto;
`;

const config = ({ config = {}, disabled }) => {
  const { placeholder: placeholderText = undefined } = config;
  return {
    key: window.common.REACT_APP_FROALA,
    initOnClick: true,
    placeholderText,
    toolbarInline: true,
    htmlSimpleAmpersand: true,
    charCounterCount: false,
    linkAutoPrefix: '',
    linkAlwaysBlank: true,
    linkStyles: {
      primary: 'Primary',
    },
    pasteDeniedAttrs: ['class', 'id', 'dir'], // enables pasting from google docs
    fontSize: ['8', '9', '10', '11', '12', '14', '18', '24', '30'],
    pasteAllowedStyleProps: [
      'font-weight',
      'font-style',
      'text-decoration',
      'color',
    ],
    pasteAllowLocalImages: false,
    pasteDeniedTags: [],
    pluginsEnabled: [
      `fullscreen`,
      `align`,
      `colors`,
      `code_view`,
      `entities`,
      `fontSize`,
      `image`,
      `lineBreaker`,
      `link`,
      `lists`,
      `paragraphFormat`,
      `quickInsert`,
      `quote`,
      `table`,
      `url`,
      `wordPaste`,
      `codeView`,
      `file`,
    ],
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    fileAllowedTypes: ['application/pdf'],
    toolbarButtonsMD: [
      'fullscreen',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      '|',
      'fontFamily',
      'fontSize',
      'textColor',
      '|',
      'paragraphFormat',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      '-',
      'insertLink',
      'insertImage',
      'insertTable',
      'insertHR',
      'clearFormatting',
      'html',
      '|',
      'undo',
      'redo',
    ],
    toolbarButtons: [
      'fullscreen',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      '|',
      'fontFamily',
      'fontSize',
      'textColor',
      'inlineStyle',
      'paragraphStyle',
      '|',
      'paragraphFormat',
      'align',
      'formatOL',
      'formatUL',
      'quote',
      'outdent',
      'indent',
      '-',
      'insertLink',
      'insertImage',
      'insertTable',
      'insertHR',
      'clearFormatting',
      'html',
      '|',
      'undo',
      'redo',
    ],
  };
};

const determineWhichValue = ({ config = {}, input = {} }) => {
  const { value: configValue = undefined } = config;
  const { value = `` } = input;
  // selecting config/value over input/value/
  return !_.isUndefined(configValue) ? configValue : value;
};

//Parses the S3 URL provided by Triage to extract the path name
export const generateCFUrl = (s3UploadUrl) => {
  const awsCFBaseUrl = window.env.AWS_CLOUDFRONT_BASE_URL;
  const s3UrlParsed = new Url(s3UploadUrl);
  return `${awsCFBaseUrl}${s3UrlParsed.pathname}`;
};

class PureMDEditor extends PureComponent {
  state = { content: ``, initControls: null };
  componentDidMount() {
    this.MDEditorSetState();
  }

  MDEditorSetState() {
    const initialValue = determineWhichValue(this.props);
    if (_.isString(initialValue)) {
      const content = marked(initialValue);
      this.setState({ content });
    }
  }

  componentDidUpdate(prevProps) {
    if (determineWhichValue(this.props) !== determineWhichValue(prevProps)) {
      this.MDEditorSetState();
    }
  }

  onUpdateModel = () => {
    const editor = this.state.initControls.getEditor();
    editor.selection.save();
    const content = decodeHtml(editor.html.get(true));
    this.setState({ content }, () => this.onChange(content));
  };

  onChange = (content) => {
    const {
      input: { onChange },
      config,
    } = this.props;

    onChange(content);

    if (config && _.isFunction(config.onChange)) {
      // passing the 'editable' onChange event through config
      config.onChange(content);
    }
  };

  /**
   * Add data-e2e-test-id attribute to the editor element
   */
  addDataTestId(editorInstance) {
    const { dataTestId = 'froala-wrapper-field' } = this.props;
    const e2eTestIdObj = e2eTestId(`${dataTestId}_element`);
    editorInstance?.el?.setAttribute(
      _.keys(e2eTestIdObj)[0],
      _.values(e2eTestIdObj)[0],
    );
  }

  handleManualController = (initControls) => {
    this.setState({ initControls });
    initControls.initialize();

    this.addDataTestId(initControls.getEditor());
  };
  /**
   * Since the 'fr-disabled' was causing that the user couldn't select the content from a disabled
   * froala editor, if we remove this class from all the divs created by froala, we can avoid this issue and keep using Froala editor
   * to display content
   */
  removeFroalaDisabledClass = (editorInstance) => {
    editorInstance.el.classList.remove('fr-disabled');
  };

  configEvents = () => ({
    events: {
      //initialize until initControls is filled
      initialized: () => {
        if (this.state.initControls === null) {
          return;
        }
        const editor = this.state.initControls.getEditor();
        const origHelpers = editor?.helpers;
        editor.helpers = {
          ...origHelpers,
          // Modify sanitizeURL function to allow for interpolation in email
          // templates.
          sanitizeURL: (url) => {
            const link = encodeURI(url)
              .replace(/%24/g, '$')
              .replace(/%7B/g, '{')
              .replace(/%7D/g, '}');
            return link;
          },
        };
        if (this.props.disabled) {
          editor.edit.off();
          this.removeFroalaDisabledClass(editor);
        } else {
          editor.edit.on();
        }
      },
      blur: this.props.input.onBlur,
      focus: this.props.input.onFocus,
      'image.beforeUpload': this.imageBeforeUpload,
      'file.beforeUpload': this.fileBeforeUpload,
      'paste.beforeCleanup': manualStringTransformations, // sanitizing on paste
      'html.get': manualStringTransformations, // sanitizing on re-render
      'save.before': manualStringTransformations, // Triggered before doing the save request
      contentChanged: this.onUpdateModel,
    },
  });

  uploadFiles = async (files) => {
    const editor = this.state.initControls.getEditor();

    for (let file of files) {
      if (file.size / 1000000 > 10 || file.type !== 'application/pdf') {
        editor.popups.hideAll([]);
        toastr.warning(
          'File Not Uploaded',
          'Please ensure that the file is less than 10 MB in size and is a PDF.',
        );
        return false;
      }
      try {
        const {
          data: { upload_url, public_url },
        } = await fileGetUrl(this.props.study_slug, {
          file_name: file.name,
        });
        const { status } = await axios.put(upload_url, file, {
          headers: { 'Content-Type': file.type },
        });

        if (status === 200) {
          editor.file.insert(public_url, file.name);
          editor.cursor.enter(true);
        }
      } catch (error) {
        toastr.error(error.message);
        logError(error);
      }
    }
  };

  fileBeforeUpload = (files) => {
    this.uploadFiles(files);
    return false;
  };

  uploadImages = async (images) => {
    const editor = this.state.initControls.getEditor();

    for (let image of images) {
      if (image.size / 1000000 > 10) {
        editor.popups.hideAll([]);
        toastr.warning(
          'Image Not Uploaded',
          'Please ensure that the image is less that 10 MB in size.',
        );
        return false;
      }
      try {
        const {
          data: { upload_url, public_url },
        } = await imageGetUrl(this.props.study_slug);

        const { status } = await axios.put(upload_url, image, {
          headers: { 'Content-Type': image.type },
        });

        if (status === 200) {
          //froala will throw an error if the url for the image is invalid (ie in development)
          editor.image.insert(public_url, null, null, editor.image.get());
        }
      } catch (error) {
        toastr.error(error.message);
        logError(error);
      }
    }
  };

  imageBeforeUpload = (images) => {
    this.uploadImages(images);
    return false;
  };

  render() {
    const { labelStyle, label, dataTest = 'froala-wrapper' } = this.props;
    return (
      <>
        <InputLabel
          labelStyle={labelStyle}
          label={`${label}`}
          className={`richTextEditor ${constructFieldClassname(
            this.props.meta,
          )}`}
        />
        <FroalaWrapper data-testid={dataTest} disabled={this.props.disabled}>
          <FroalaEditor
            tag="textarea"
            model={this.state.content}
            onManualControllerReady={this.handleManualController}
            config={{ ...config(this.props), ...this.configEvents() }}
          />
        </FroalaWrapper>
      </>
    );
  }
}

// this is because Froala doesn't work under JSDOM and we need to swap it for unit tests.
const MDEditor = navigator.userAgent.includes('jsdom')
  ? HorizontalTextarea
  : PureMDEditor;

export default connect(
  ({
    graph: {
      content: { slug: study_slug },
    },
  }) => ({
    study_slug,
  }),
)(MDEditor);

import { Input, Select } from '@evidation/ui';
import { RefObject, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Attributes,
  Included,
  QAOverridesData,
  Relationships,
} from 'src/api/qaOverrides';
import { event_statuses } from 'src/constants';
import styled from 'styled-components';
import { parseSlug } from 'src/routes/edit/forms/common';
import { filterSideEffects } from './qaOverrides.utils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const statusOptions = event_statuses.map((status) => ({
  value: status,
  label: status,
}));
const QAOverrideForm: React.FC<{
  sideEffects: Included[];
  graphId: string;
  postOverride: (override) => void;
  isSending: boolean;
  defaultValue?: QAOverridesData;
  updateOverride: (override) => void;
  formRef: RefObject<HTMLFormElement>;
  cancelEdit: () => void;
  currentOverrides: QAOverridesData[];
}> = ({
  sideEffects,
  graphId,
  postOverride,
  isSending,
  defaultValue,
  updateOverride,
  formRef,
  cancelEdit,
  currentOverrides,
}) => {
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<QAOverridesData>({
    defaultValues: defaultValue,
  });
  const slug = watch('attributes.qa_value.params.slug');
  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue);
    } else {
      reset();
    }
  }, [defaultValue, reset]);
  const availableSideEffects = useMemo(
    () =>
      // pass overrides if creating pass empty array if editing
      filterSideEffects(sideEffects, currentOverrides, defaultValue).map(
        (sideEffect) => ({
          label: sideEffect.attributes.params.slug,
          value: sideEffect.id,
        }),
      ),
    [sideEffects, currentOverrides, defaultValue],
  );
  const onSubmit = (data: {
    attributes: Attributes;
    relationships: Relationships;
  }) => {
    data.attributes.qa_value.type = 'event_emission';
    if (defaultValue) {
      updateOverride({
        data: {
          data: {
            id: defaultValue.id,
            type: 'qa_override',
            attributes: {
              qa_value: {
                ...data.attributes.qa_value,
              },
            },
            relationships: {
              resource: {
                data: data.relationships.resource.data,
              },
            },
          },
        },
        graphId,
      });
    } else {
      postOverride({
        data: {
          data: {
            id: undefined,
            type: 'qa_override',
            ...data,
          },
        },
        graphId,
      });
    }

    reset();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Container>
        <Controller
          control={control}
          name="relationships"
          rules={{ required: true }}
          render={({ field: { value } }) => (
            <Select
              error={
                errors.relationships ? 'This field is required' : undefined
              }
              label="Survey Data Trigger to override"
              options={availableSideEffects}
              onChange={(selected) =>
                setValue(
                  `relationships`,
                  {
                    resource: {
                      data: {
                        id: selected.value,
                        type: 'side_effect',
                      },
                    },
                    schema: {
                      data: {
                        id: 'side_effect_params',
                        type: 'schema',
                      },
                    },
                  },
                  {
                    shouldValidate: true,
                  },
                )
              }
              value={value?.resource?.data?.id}
            />
          )}
        />
        <Input
          id="attributes.qa_value.params.slug"
          label="Event Name"
          value={slug}
          error={
            errors?.attributes?.qa_value?.params?.slug
              ? 'Slug is a required field'
              : undefined
          }
          {...register(`attributes.qa_value.params.slug`, {
            required: true,
            setValueAs: (value) => parseSlug(value),
          })}
        />
        <Controller
          control={control}
          name="attributes.qa_value.params.status"
          rules={{ required: true }}
          render={({ field: { value } }) => (
            <Select
              error={
                errors?.attributes?.qa_value?.params?.status
                  ? 'Status is a required field'
                  : undefined
              }
              label="Event Status"
              options={statusOptions}
              onChange={(selected) =>
                setValue(`attributes.qa_value.params.status`, selected.value, {
                  shouldValidate: true,
                })
              }
              value={value}
            />
          )}
        />
        <div style={{ paddingTop: '2rem' }}>
          <button type="submit" disabled={isSending}>
            Save
          </button>
        </div>
        <div style={{ paddingTop: '2rem' }}>
          {defaultValue && (
            <button onClick={() => cancelEdit()} disabled={isSending}>
              Cancel
            </button>
          )}
        </div>
      </Container>
    </form>
  );
};

export default QAOverrideForm;

import styled from 'styled-components';
import AdvancedTable, {
  StyledTable as DefaultStyledTable,
} from '@evidation/advanced-table';
import DropdownMenu from 'react-dd-menu';
import type { Attributes, Included, Relationships } from 'src/api/qaOverrides';
import { useState } from 'react';
type QAOverridesProps = {
  tableData: {
    prodValue: Included | undefined;
    type: string;
    id?: string;
    attributes: Attributes;
    relationships: Relationships;
  }[];
  updateOverride: (override) => void;
  deleteOverride: (override) => void;
};

const TableDropdown = styled(DropdownMenu)`
  .dd-menu-items {
    max-width: 160px;
    z-index: 30;
  }

  ul.dd-items-right {
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

    button {
      border: none;

      &:hover {
        background-color: #e3e3e3;
      }
    }
  }
`;

const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
`;

const StyledTable = styled(DefaultStyledTable)`
  .record-counter {
    display: none;
  }

  .column-filter-input {
    display: none;
  }

  .table-container {
    overflow-x: scroll;

    table {
      width: 100%;
      th {
        padding: 0.5em 0.5em 0;
      }
    }
    margin-bottom: 40px;
  }
`;

const TableCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const columns = ['description', ' production_value', 'qa_value'];

const DescriptionsCell = (props) => {
  return <p>{props.rowData?.attributes?.description}</p>;
};

const ProductionValueCell = (props) => {
  return (
    <pre>
      {JSON.stringify(props.rowData?.prodValue?.attributes || '', undefined, 2)}
    </pre>
  );
};

const QAValueCell = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const options = {
    isOpen,
    align: 'right',
    size: 'md',
    closeOnInsideClick: false,
    close: () => setIsOpen(false),
    toggle: <DropdownButton onClick={handleDropdownClick}>...</DropdownButton>,
  };

  return (
    <TableCell>
      <pre>
        {JSON.stringify(props.data.attributes?.qa_value || '', undefined, 2)}
      </pre>
      <TableDropdown {...options}>
        <button
          onClick={() => {
            props.updateOverride(props.data);
            handleDropdownClick();
          }}
        >
          Edit
        </button>
        <button
          onClick={() => {
            props.deleteOverride(props.data);
            handleDropdownClick();
          }}
        >
          Delete
        </button>
      </TableDropdown>
    </TableCell>
  );
};

const QAOverridesTable: React.FC<QAOverridesProps> = ({
  tableData,
  updateOverride,
  deleteOverride,
}) => {
  const customCells = {
    description: DescriptionsCell,
    production_value: ProductionValueCell,
    qa_value: (props) => (
      <QAValueCell
        {...{ data: props.rowData, updateOverride, deleteOverride }}
      />
    ),
  };
  return (
    <StyledTable>
      <AdvancedTable
        data={{ columns, data: tableData }}
        sort={false}
        search={false}
        customCells={customCells}
        rowKey="id"
      />
    </StyledTable>
  );
};
export default QAOverridesTable;

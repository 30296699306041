import Disqualification from './disqualification';
import EmailInvitation from './email_invitation';
import EventDelays from './event_delays';
import External from './external';
import General from './general';
import Integrations from './integrations';
import Webhooks from './webhooks';
import Languages from './languages';
import ProductionSupport from './production_support';
import display_participants from './display_participants';
import incoming from './incoming';
import outgoing from './outgoing';
import qaOverrides from './qaOverrides';

/**
 * @description Checks the current user's roles, to see if the Settings tab displays the "Languages" sub tab.
 * @param {array} roles - could be something like ['owner', 'publisher' ...]
 * @returns {number}
 */
export const is_current_user_allowed_language_tab = (roles = []) => {
  const allowed_roles_for_languages_tab = ['owner', 'publisher'];
  const intersection = roles.filter((role_name) =>
    allowed_roles_for_languages_tab.includes(role_name),
  );
  return intersection.length;
};

const tab_names = {
  display_and_participants: 'Display & Participants',
  event_delays: 'Event Delays',
  production_support: 'Production Support',
  email_invitation: 'Email Invitation',
  languages: 'Languages',
  webhooks: 'Webhooks',
  integrations: 'Integrations',
  qa_overrides: 'QA Overrides',
};

export default {
  form: ({ i18n_enabled = false, masonryEnabled = false, ...t }) => {
    let settings_tabs = {
      General,
      [tab_names.display_and_participants]: display_participants,
      [tab_names.event_delays]: EventDelays,
      External,
      [tab_names.production_support]: ProductionSupport,
      Disqualification,
      [tab_names.email_invitation]: EmailInvitation,
      [tab_names.webhooks]: Webhooks,
      [tab_names.integrations]: Integrations,
      [tab_names.qa_overrides]: qaOverrides,
    };
    if (masonryEnabled) {
      // This does not work with Masonry yet.
      // TODO: SI-9574
      delete settings_tabs[tab_names.integrations];
    }

    if (
      i18n_enabled &&
      is_current_user_allowed_language_tab(t.content?.roles)
    ) {
      settings_tabs = {
        ...settings_tabs,
        [tab_names.languages]: (params) => Languages(t, params),
      };
    }
    return settings_tabs;
  },
  incoming,
  outgoing,
};

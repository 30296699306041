import { Amplify } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getCognitoMeta } from 'src/api/meta';
import * as Auth from 'aws-amplify/auth';
import Login from 'src/components/login';
import { useTypedSelector } from 'src/redux/utils';
import {
  getAuthorization,
  loginUser,
  logout,
  refreshStudies,
  loggedIn,
  setLocalstorage,
} from '../actions/user_actions';
import { connect, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Hub } from 'aws-amplify/utils';
import styled from 'styled-components';
import { logError } from 'src/utils/errorLogger';

const text = {
  logIn: 'Log in',
  errorFetchingConfig: 'Error fetching Cognito configuration for Okta login.',
  problemLoggingIn: 'There was a problem logging you in.',
};

type AuthEntryProps = {
  children: React.ReactElement;
  loginComponent?: React.ComponentType;
  configureAmplify?: any;
  loginUser: any;
  logout: any;
  showErrorMessage?: typeof toastr.error;
  hub?: any;
  auth?: any;
};

const CognitoSettingsFetchError = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorContainer = styled.div`
  border-radius: 10px;
  padding: 60px 32px 60px 32px;
  background: white;
  box-shadow: 0px 1px 4px 0px #0000001f;
  width: 450px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const setUserContent = async ({ token, username, dispatch }) => {
  try {
    const authorization = await getAuthorization(token, username);
    const payload = { token, username, authorization };
    setLocalstorage(payload);
    dispatch(loggedIn(payload));
    await refreshStudies(dispatch);
  } catch (error) {
    console.error(error);
    logError(error);
    logout();
  }
};

export const AuthEntry: React.FC<AuthEntryProps> = ({
  children,
  loginComponent: LoginComponent = Login,
  configureAmplify = Amplify.configure,
  showErrorMessage = toastr.error,
  loginUser,
  hub = Hub,
  auth = Auth,
  logout,
}) => {
  const dispatch = useDispatch();
  const [authenticated, setAuthenticated] = useState(false);
  const user = useTypedSelector((state) => state.user);
  const {
    data: cognitoAuthConfigResponse,
    error,
    isLoading,
  } = useQuery(['getCognitoAuthConfig'], () => getCognitoMeta(), {
    retry: false,
    // The auth config is not bound to change, so we don't need to refetch.
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isLoading) {
      if (cognitoAuthConfigResponse) {
        const redirectUrl = `${window.location.origin}/`;
        const clientId = cognitoAuthConfigResponse.data.client_id;

        configureAmplify({
          Auth: {
            Cognito: {
              region: cognitoAuthConfigResponse.data.region,
              userPoolId: cognitoAuthConfigResponse.data.user_pool_id,
              clientId,
              userPoolClientId: clientId,
              userPoolWebClientId: clientId,
              loginWith: {
                oauth: {
                  domain: cognitoAuthConfigResponse.data.user_pool_domain,
                  responseType: cognitoAuthConfigResponse.data.response_type,
                  redirectSignIn: [redirectUrl],
                  redirectSignOut: [redirectUrl],
                  scopes: cognitoAuthConfigResponse.data.scope,
                },
              },
            },
          },
        });
        auth.fetchAuthSession().then((session) => {
          if (session.tokens?.idToken) {
            const token = session.tokens.idToken.toString();
            const username = session.tokens.idToken.payload?.email as string;
            if (!user.authenticated) {
              loginUser({
                token,
                username,
              });
              setUserContent({ token, username, dispatch }).then(() => {
                setAuthenticated(true);
              });
            } else {
              if (!authenticated) {
                loginUser({
                  token: '',
                  username: '',
                });
                setUserContent({ token, username, dispatch }).then(() => {
                  setAuthenticated(true);
                });
              }
            }
          } else {
            if (user.authenticated) {
              // log the user out if they have a local session and AWS has no session
              logout();
            }
          }
        });

        const listener = async ({ payload: { event, message } }) => {
          if (event === 'signedIn') {
            const session = await auth.fetchAuthSession();
            loginUser({
              token: session?.tokens?.idToken?.toString(),
              username: session?.tokens?.idToken?.payload?.email,
            });
            setAuthenticated(true);
          } else if (event === 'signIn_failure') {
            showErrorMessage('Error', text.problemLoggingIn);
            throw new Error(
              `Received Cognito event 'signIn_failure' with message: "${message}".`,
            );
          }
        };
        const hubListenerCancel = hub.listen('auth', listener);
        return () => {
          hubListenerCancel();
        };
      }
    }
  }, [
    isLoading,
    configureAmplify,
    cognitoAuthConfigResponse,
    user,
    loginUser,
    logout,
    showErrorMessage,
    hub,
    authenticated,
    auth,
    dispatch,
  ]);
  // hopefully this will never be seen by an end user.
  if (error) {
    return (
      <CognitoSettingsFetchError>
        <ErrorContainer>
          <h1>Unable to load critical configuration (Cognito)</h1>
          App cannot load the needed configuration to continue.
        </ErrorContainer>
      </CognitoSettingsFetchError>
    );
  }
  if (authenticated) {
    return children;
  }
  return <LoginComponent />;
};

export default connect(null, {
  loginUser,
  logout,
})(AuthEntry);

import { Button } from '@evidation/ui';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import EntitySelector from './entity-selector';
import { useQuestionsInTileSequence } from '../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const Fieldset = styled.fieldset`
  & * {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
`;

type EntitySelectionFormValues = {
  entityType: string;
  entityId: string;
  tileId: string;
};

export default function EntitySelectionForm({
  type,
  tile,
  onSubmit,
}: {
  type: string;
  tile: any;
  onSubmit: (values: EntitySelectionFormValues) => any;
}) {
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EntitySelectionFormValues>({
    defaultValues: {
      entityType: 'question',
      entityId: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        entityType: yup.string().required('Entity type is required'),
        entityId: yup.string().required('Question is required'),
      }),
    ),
  });

  const questionsInTile = useQuestionsInTileSequence(tile);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset>
        <legend>Select the item to add {type} logic to</legend>
        <EntitySelector
          questions={questionsInTile}
          entityId={watch('entityId')}
          entityType={watch('entityType')}
          tileId={watch('tileId')}
          idError={errors.entityId?.message}
          typeError={errors.entityType?.message}
          setEntityType={(v) =>
            setValue('entityType', v, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
          setEntityValue={(v) =>
            setValue('entityId', v, { shouldValidate: true, shouldDirty: true })
          }
          setTileId={(v) =>
            setValue('tileId', v, { shouldValidate: true, shouldDirty: true })
          }
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="light"
            style={{ minWidth: 150, justifyContent: 'center' }}
          >
            Next
          </Button>
        </div>
      </Fieldset>
    </form>
  );
}

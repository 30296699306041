import * as yup from 'yup';
import { Form, Tile } from 'src/redux/graph';

import {
  JsonLogicAll,
  JsonLogicOr,
  JsonLogicLessThan,
  JsonLogicAnd,
  JsonLogicInArray,
  JsonLogicEqual,
  JsonLogicGreaterThan,
} from 'json-logic-js';
import stripHtml from 'src/utils/stripHtml';
import { useTypedSelector } from 'src/redux/utils';
import { useMemo } from 'react';

type JsonCondition =
  | JsonLogicOr
  | JsonLogicAll
  | JsonLogicLessThan
  | JsonLogicAnd
  | JsonLogicInArray
  | JsonLogicEqual
  | JsonLogicGreaterThan;

export type LogicFormValues = {
  targetType: string;
  targetId: string;
  tileId: string;
  conditions: CompoundCondition[];
};

export type SimpleCondition = {
  value: any;
  target_name?: string;
  targetType?: string;
  target_interface?: string;
  targetTileId: string;
  sourceTargetType?: string;
  operator: string;
  conditions?: never;
  user_characteristic_target?: string;
};

export type CompoundCondition = {
  parent?: number | null;
  depth: number;
  operator: string;
  conditions: Condition[];
};

export type Condition = SimpleCondition | CompoundCondition;

export type Entity<ContentLoaded extends boolean = false> = {
  defaultValues?: any;
} & (ContentLoaded extends true
  ? {
      entityId: string;
      entityType: string;
      tileId: string;
    }
  : {
      entityId?: string;
      entityType?: string;
      tileId?: string;
    });

export type TileQuestion = {
  tileId: string;
  form: Form;
};

const conditionValidation = yup
  .object()
  .shape({
    parent: yup.number().nullable(),
    depth: yup.number().optional(),
    operator: yup.string().required('Operation is required'),
    conditions: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            targetType: yup.string().required(),
            componentId: yup.mixed().when('targetType', {
              is: 'question',
              then: (schema) => schema.required('Question Value is Required'),
            }),
            operator: yup.string().required('Operation is required'),
            value: yup.mixed().when('operator', {
              is: 'present',
              then: (schema) => schema.optional(),
              otherwise: yup.lazy((val) =>
                Array.isArray(val)
                  ? yup
                      .array()
                      .of(yup.number().required())
                      .required()
                      .min(1, 'Answer Choice Required')
                  : yup.string().when('targetType', {
                      is: 'user-characteristic',
                      then: (schema) =>
                        schema.required('Answer Value Required').test({
                          name: 'number',
                          exclusive: false,
                          message:
                            'Only strings are currently supported by user-characteristic logic',
                          test: function (value) {
                            if (Number.isNaN(Number(value))) {
                              return true;
                            }
                            return false;
                          },
                        }),
                      otherwise: (schema) =>
                        yup.string().when('sourceTargetType', {
                          is: 'answer_value',
                          then: (schema) =>
                            schema.required('Answer Value Required').test({
                              exclusive: false,
                              message:
                                'Only numbers are currently supported by answer_value logic',
                              test: (value) => !Number.isNaN(Number(value)),
                            }),
                          otherwise: (schema) =>
                            schema.required('Answer Value Required'),
                        }),
                    }),
              ),
            }),
            sourceTargetType: yup.string().when('targetType', {
              is: 'question',
              then: (schema) => schema.required('Select a value type'),
              otherwise: (schema) => schema.optional(),
            }),
            user_characteristic_target: yup.string().when('targetType', {
              is: 'user-characteristic',
              then: (schema) =>
                schema.required('Please enter a user characteristic value'),
              otherwise: (schema) => schema.optional(),
            }),
            target_name: yup
              .string()
              .when('targetType', {
                is: 'question',
                then: (schema) =>
                  schema.required('Please enter a target question.'),
                otherwise: (schema) => schema.optional(),
              })
              .optional(),
          })
          .required(),
      )
      .required()
      .min(1, 'At least one condition is required'),
  })
  .required();

export const validations = (type: string) =>
  yup.object().shape({
    targetType: yup
      .string()
      .required(
        'Please select the entity type you would like to target skipping to.',
      ),
    targetId:
      type === 'skip'
        ? yup.string().required('Please select an entity to to skip to')
        : yup.string().nullable(),
    parent: yup.number().optional(),
    depth: yup.number().optional(),

    conditions: yup.array().of(conditionValidation).required(),
  });

export const findQuestionsForTiles = (tiles: Tile[]): TileQuestion[] => {
  return tiles.flatMap((tile) =>
    (tile.content.experiences?.default?.form ?? [])
      .flat(1)
      .filter((item) => item.accepts_data)
      .map((item) => ({
        tileId: tile.id,
        form: item,
      })),
  );
};

export const findTileQuestion = (
  tile: Tile,
  name?: string,
): TileQuestion | undefined => {
  const questions = findQuestionsForTiles([tile]);
  const question = questions.find(
    // fallback to name here so we can pass in path segments from the url and still get our initial question
    (v) => v.form.component_id === name || v.form.name === name,
  );

  return question;
};

export const findQuestionAnswers = (question: any) => {
  return [
    ...(question?.possible_answers ?? []),
    ...(question?.mutually_exclusive_answers ?? []),
  ].map((pa) => ({
    label: stripHtml(pa.label),
    value: pa.answer,
  }));
};

export const findTileQuestionPosition = (tile: Tile, questionId: string) => {
  if (tile?.content?.experiences?.default?.form) {
    for (const [
      idx,
      page,
    ] of tile?.content?.experiences?.default?.form?.entries()) {
      const match = page.findIndex((p: Form) => p.component_id === questionId);
      if (match >= 0) {
        return [idx, match];
      }
    }
  }
  return [null, null];
};

const getTargetName = (rule: any) => {
  if (rule.source === 'user_data') {
    return rule.key;
  } else if (rule.source === 'survey_answer') {
    return rule.component_id;
  }
};

export const transformIncoming = (
  _tile: Tile,
  question: Form,
  type: string,
) => {
  const flattenJSONLogic = (
    condition: JsonCondition,
    rules: any[] = [],
    depth: number,
    parent: undefined | null | number = undefined,
  ) => {
    for (const [operator, subRules] of Object.entries<any[]>(condition)) {
      if (operator === 'and' || operator === 'or') {
        // compound conditions get pushed to the root conditions array of the form.
        // parent null siginfies to the form the root condition
        // all other items are nested within the parent item at least
        // nesting is restricted to 2 levels deep controlled through the UI and necessary for the current
        // transformOutgoing function
        rules.push({
          operator,
          depth,
          parent,
          conditions: [],
        });
        // increase the depth for the next occurance of a compound condition.
        let newDepth = depth + 1;
        // if parent is null then set it to 0 if its  a number increment.
        let newParent = typeof parent === 'number' ? parent + 1 : 0;
        // recursively call this fn with each child rule with the incremented values.
        subRules.forEach((v: any) =>
          flattenJSONLogic(v, rules, newDepth, newParent),
        );
      } else {
        //safety checks for oob rerenders
        if (!Array.isArray(subRules)) {
          return;
        } else if (subRules.length !== 2) {
          if (operator === 'present') {
            const [right] = subRules;
            rules[rules.length - 1].conditions.push({
              target_name: getTargetName(right),
              user_characteristic_target: getTargetName(right),
              targetType: right?.client_metadata?.targetType ?? '',
              targetTileId: right.tile_uuid ?? '',
              sourceTargetType: right?.client_metadata?.sourceTargetType ?? '',
              operator,
            });
          }
          return;
        }
        // simple condition case
        // the first item in the rules array represents the left hand of the Operation
        // the second item represents the right hand
        const [right, left] = subRules;
        // push the simple condition structure onto the conditions stack in the correct position
        rules[rules.length - 1].conditions.push({
          value: left.value,
          target_name: getTargetName(right),
          user_characteristic_target: getTargetName(right),
          targetType: right?.client_metadata?.targetType ?? '',
          sourceTargetType: right?.client_metadata?.sourceTargetType ?? '',
          operator,
          targetTileId: right.tile_uuid,
        });
      }
    }
    return rules;
  };

  const conditionKey = type === 'skip' ? 'skip_logic' : 'display_condition';
  if (question[conditionKey]) {
    const condition =
      conditionKey === 'skip_logic'
        ? question[conditionKey]!.condition
        : question[conditionKey];
    const formValues = condition
      ? flattenJSONLogic(
          condition,
          [],
          0,
          null, // passing in null here signifies the root condition to the transformer.
        )
      : [];
    return {
      conditions: formValues,
      targetType: 'question',
      targetId:
        conditionKey === 'skip_logic'
          ? question[conditionKey]!.skip_to?.component_id!
          : null,
      tileId:
        conditionKey === 'skip_logic'
          ? question[conditionKey]!.skip_to?.tile_uuid!
          : null,
    };
  }
  return null;
};

function getValueTypeFromIntf(intf: string, value: any, operator: string) {
  switch (intf) {
    case 'radio':
    case 'number':
    case 'select-number-range':
    case 'range_slider':
    case 'selectadvanced':
      return Number(value);
    case 'checkboxWithNone':
    case 'checkbox':
      if (operator === 'includes') {
        return Number(value);
      }
      return [Number(value)];
    default:
      return value;
  }
}

function handleValueTransform(
  condition: SimpleCondition,
  sourceQuestion?: Form,
) {
  if (sourceQuestion?.mutually_exclusive_answers) {
    for (const opt of sourceQuestion.mutually_exclusive_answers) {
      if (Number(condition.value) === opt.answer) {
        return getValueTypeFromIntf(
          sourceQuestion.interface,
          condition.value,
          condition.operator,
        );
      }
    }
  }

  if (sourceQuestion?.interface && !Array.isArray(condition.value)) {
    return getValueTypeFromIntf(
      sourceQuestion.interface,
      condition.value,
      condition.operator,
    );
  }
  return condition.value;
}

export const transformOutgoing = ({
  entitySelected,
  type,
  values,
  tile,
  sequenceTiles,
}: {
  entitySelected: Entity;
  type: string;
  values: LogicFormValues;
  tile: Tile;
  sequenceTiles: TileQuestion[];
}) => {
  const transformCondition = (condition: Condition) => {
    // compound condition and || or
    if (condition.conditions) {
      return {
        [condition.operator]: [
          ...condition.conditions.map((condition) =>
            transformCondition(condition),
          ),
        ],
      };
    }

    const getConditionData = () => {
      if (
        condition.targetType === 'answer_value' ||
        condition.targetType === 'question' ||
        condition.targetType === 'answer_choice'
      ) {
        let question = findTileQuestion(
          tile,
          condition.target_name || entitySelected.entityId,
        );

        if (!question && condition.targetTileId) {
          const questionsForTargetTile = sequenceTiles.filter(
            (v) => v.tileId === condition.targetTileId,
          );
          question = questionsForTargetTile.find(
            (v) => v.form.component_id === condition.target_name,
          );
        }

        const payload: any = [
          {
            source: 'survey_answer',
            component_id: condition.target_name || entitySelected.entityId,
            tile_uuid: condition.targetTileId || entitySelected.tileId,
            client_metadata: {
              sourceTargetType: condition?.sourceTargetType,
              targetType: condition?.targetType,
            },
          },
        ];

        if (condition.operator !== 'present') {
          payload.push({
            source: 'constant',
            value: handleValueTransform(condition, question?.form),
          });
        }

        return payload;
      } else if (condition.targetType === 'user-characteristic') {
        const payload: any = [
          {
            source: 'user_data',
            key: condition.user_characteristic_target,
            client_metadata: {
              sourceTargetType: condition?.sourceTargetType,
              targetType: condition.targetType,
            },
          },
        ];

        if (condition.operator !== 'present') {
          payload.push({
            source: 'constant',
            value: condition.value,
          });
        }
        return payload;
      }
    };
    // simple condition
    return {
      [condition.operator]: getConditionData(),
    };
  };
  let conditionKey = type === 'display' ? 'display_condition' : 'skip_logic';
  if (!values.conditions?.[0]) {
    return { [conditionKey]: undefined };
  }
  let payload = {
    [conditionKey]: {
      condition:
        values.conditions[0].conditions.length > 0
          ? {
              [values.conditions[0].operator]:
                values.conditions[0].conditions.map((condition) =>
                  transformCondition(condition),
                ),
            }
          : { [values.conditions[0].operator]: [] },
      skip_to:
        type === 'skip'
          ? {
              type: 'survey_question',
              component_id: values.targetId,
              tile_uuid: values.tileId,
            }
          : undefined,
    },
  };
  // here be dragons
  // walk the conditions calling transformCondition to generate the rule
  // this is hard coded to only support 2 levels of nesting per the requirements
  // We should probably improve this to support more levels if that becomes an ask in the future.
  for (const condition of values?.conditions) {
    const root =
      payload[conditionKey].condition?.[values.conditions[0].operator];
    if (condition.parent !== null && condition.parent === 0) {
      payload[conditionKey].condition[values.conditions[0].operator] = [
        ...(payload?.[conditionKey]?.condition?.[
          values.conditions[0].operator
        ] as any),
        transformCondition(condition),
      ];
    }
    // second layer of nesting
    // should be refactored if time allows
    if (condition.parent !== null && condition.parent === 1) {
      const parentItem = root?.[root.length - 1];
      parentItem[values.conditions[condition.parent].operator].push(
        transformCondition(condition),
      );
    }
  }
  // Triage expects display_condition to be a condition directly
  // thus theres no need for the format to be display_condition.condition = Condition
  // triage expects display_condition = Condition
  if (conditionKey === 'display_condition') {
    //@ts-expect-error
    payload = { [conditionKey]: payload[conditionKey].condition };
  }

  return payload;
};

//build logic add/edit link for use in summary
export const buildLogicLink = (
  match: any,
  pageId: number,
  name: string,
  skip: any,
) => {
  return `/study/${match.params.graph_id}/configure/${match.params.resource}/${
    match.params.identifier
  }/components/${pageId}/${name}/logic/add-edit?type=${
    skip ? 'skip' : 'display'
  }`;
};

type UseQuestionsInTileSequenceOptions = {
  /**
   * Whether to return the components preceding the given tile/component.
   */
  returnPreceding?: boolean;
  /**
   * Whether to return the components following the given tile/component.
   */
  returnFollowing?: boolean;
  /**
   * Whether to return the components that belong and match the given tile/component
   */
  returnDirectMatch?: boolean;
} & ( // `componentId` can only be used together with `includeCurrent`
  | {
      /**
       * If given, we treat the "current" entity as this specific question within
       *   the tile; otherwise, we treat the entire tile as "current".
       */
      componentId: string;
      /**
       * Whether to return the current component, if given, or components from
       *   the current tile otherwise.
       */
      includeCurrent?: boolean;
    }
  | {
      includeCurrent?: never;
      componentId?: never;
    }
);

/**
 * Returns a flat array of questions in this tile's sequence. Used to generate
 *   valid options for the EntitySelector component.
 */
export const useQuestionsInTileSequence = (
  currentTile: Tile,
  {
    returnPreceding = false,
    returnFollowing = false,
    returnDirectMatch = false,
    componentId,
    includeCurrent,
  }: UseQuestionsInTileSequenceOptions = {},
) => {
  const sequence = useTypedSelector(
    (state) =>
      Object.values(state.sequences).find((sequence) =>
        sequence.tile_ids.includes(currentTile.id),
      )!,
  );
  const shouldShowSurveysFromSequence =
    !!sequence.content.sequential_surveys &&
    (returnPreceding || returnFollowing);
  const allTiles = useTypedSelector((state) => state.tiles);
  const surveysInSequence = useMemo(
    () =>
      shouldShowSurveysFromSequence
        ? sequence.tile_ids
            .map((tileId) => allTiles[tileId])
            .filter((tile) => tile.content.type === 'survey')
        : [currentTile],
    [allTiles, currentTile, sequence.tile_ids, shouldShowSurveysFromSequence],
  );

  const questionsInSequence = useMemo(
    () =>
      returnDirectMatch
        ? findQuestionsForTiles(surveysInSequence).filter(
            (tile) => tile.tileId === currentTile.id,
          )
        : findQuestionsForTiles(surveysInSequence),
    [surveysInSequence, returnDirectMatch, currentTile.id],
  );

  const currentQuestion = componentId
    ? questionsInSequence.find(
        (question) => question.form.component_id === componentId,
      )!
    : null;

  const indexOfCurrentSurveyInSequence = surveysInSequence.indexOf(currentTile);
  const indexOfCurrentQuestionInSequence = componentId
    ? questionsInSequence.indexOf(currentQuestion!)
    : -1;

  return useMemo(
    () =>
      questionsInSequence.filter((question) => {
        const tile = surveysInSequence.find(
          (tile) => tile.id === question.tileId,
        )!;
        if (componentId) {
          if (
            !returnPreceding &&
            questionsInSequence.indexOf(question) <
              indexOfCurrentQuestionInSequence
          ) {
            return false;
          }
          if (
            !returnFollowing &&
            questionsInSequence.indexOf(question) >
              indexOfCurrentQuestionInSequence
          ) {
            return false;
          }
          if (
            includeCurrent === false &&
            question.form.component_id === componentId
          ) {
            return false;
          }
        } else {
          if (
            !returnPreceding &&
            surveysInSequence.indexOf(tile) < indexOfCurrentSurveyInSequence
          ) {
            return false;
          }
          if (
            !returnFollowing &&
            surveysInSequence.indexOf(tile) > indexOfCurrentSurveyInSequence
          ) {
            return false;
          }
          if (includeCurrent === false && question.tileId === currentTile.id) {
            throw new Error(
              'Cannot use `includeCurrent` without `componentId`.',
            );
          }
        }
        return true;
      }),
    [
      componentId,
      currentTile.id,
      includeCurrent,
      indexOfCurrentQuestionInSequence,
      indexOfCurrentSurveyInSequence,
      questionsInSequence,
      returnFollowing,
      returnPreceding,
      surveysInSequence,
    ],
  );
};

export const getDefaultTargetTypeFromInterface = (intf: string) => {
  switch (intf) {
    case 'checkboxWithNone':
    case 'selectadvanced':
    case 'radio':
    case 'radioinput':
      return 'answer_choice';
    default:
      return 'answer_value';
  }
};

export const getDefaultOperatorFromInterface = (intf: string) => {
  if (intf === 'checkbox' || intf === 'checkboxWithNone') {
    return 'includes';
  }
  return '==';
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LogicForm from './logic-form';
import EntitySelectionForm from './entity-selection-form';
import {
  findTileQuestion,
  findTileQuestionPosition,
  LogicFormValues,
  transformOutgoing,
  transformIncoming,
  Entity,
  useQuestionsInTileSequence,
} from '../utils';
import { Banner, Button, Flex } from '@evidation/ui';
import { ConfirmationModalStyles, LogicModalStyles } from './components';
import { apiGraph } from 'src/api';
import { find_sequence_from_tile } from 'src/middlewares/slate_middleware';
import { updateTile as updateTileAction } from 'src/actions/tiles_actions';
import { useTypedSelector } from 'src/redux/utils';

export default function AddEditLogicForm({
  entityId,
  entityType,
  ...rest
}: {
  type?: string;
  entityId?: string;
  entityType?: string;
  match?: Record<string, any>;
  [key: string]: any;
}) {
  const dispatch = useDispatch();
  const [entitySelected, setEntitySelected] = useState<Entity>({
    entityId,
    entityType,
    defaultValues: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const type =
    new URLSearchParams(window.location.search).get('type') || 'skip';

  useEffect(() => {
    if (entityId && entityId !== entitySelected.entityId) {
      setEntitySelected({ entityId, entityType });
    }
  }, [entitySelected, entityId, entityType]);
  const sequences = useTypedSelector((state) => state.sequences);
  const currentTile = useTypedSelector(
    (state) => state.tiles[rest.match?.params.identifier],
  );

  const sequenceTiles = useQuestionsInTileSequence(currentTile, {
    returnPreceding: true,
  });

  // TODO: Not Compatible with masonry; needs API support
  useEffect(() => {
    if (rest?.match?.params) {
      const { path_segment_1 = undefined, path_segment_2 = undefined } =
        rest.match.params;
      const question =
        findTileQuestion(currentTile, path_segment_1) ||
        findTileQuestion(currentTile, path_segment_2);
      if (question) {
        setEntitySelected({
          entityId: question.form.component_id,
          entityType: 'question',
          tileId: question.tileId,
          defaultValues: transformIncoming(currentTile, question.form, type),
        });
      }
    }
  }, [rest.match, currentTile, type, submitCount]);

  const handleSubmit = async (values: LogicFormValues) => {
    setLoading(true);
    const payload = transformOutgoing({
      entitySelected,
      type,
      values,
      tile: currentTile,
      sequenceTiles,
    });
    const [pageIdx, itemIdx] = findTileQuestionPosition(
      currentTile,
      entitySelected.entityId!,
    );
    // update the question in place and save.
    const working = { ...currentTile };

    working.content.experiences!.default.form[pageIdx][itemIdx] = {
      ...working.content.experiences!.default?.form[pageIdx][itemIdx],
      ...payload,
    };

    // get the sequence id for the api call
    const sequence_uuid = find_sequence_from_tile(
      { sequences },
      'sequences',
      rest?.match?.params?.identifier,
    );
    try {
      await apiGraph.update_tile(
        rest?.match?.params?.graph_id,
        sequence_uuid,
        rest?.match?.params?.identifier,
        working,
      );
      dispatch(updateTileAction(working));
      setIsSuccess(true);
      setLoading(false);
      setHasChanges(false);
      setSubmitCount((prev) => prev + 1);
    } catch (err: unknown) {
      setIsSuccess(false);
      setError('Something went wrong saving your changes.');
    } finally {
      setLoading(false);
    }
  };

  const handleHasChanged = (hasChanged: boolean) => {
    if (hasChanges !== hasChanged) {
      setHasChanges(hasChanged);
    }
  };

  const handleCancel = () => {
    if (hasChanges) {
      setShowConfirmation(true);
    } else {
      rest.history.goBack();
    }
  };

  return (
    <div style={{ padding: 30 }}>
      <LogicModalStyles />

      <Flex items="center" justify="space-between">
        <h1
          style={{
            marginBottom: '.4em',
            textTransform: 'capitalize',
            fontSize: '2rem',
          }}
        >
          {entitySelected?.defaultValues ? 'Edit ' : ''}
          {type} Logic
        </h1>
        <Button onClick={handleCancel} variant="ghost">
          X
        </Button>
      </Flex>

      <Banner variant="informational" show={isSuccess}>
        <Flex items="center" justify="space-between">
          <p>Information successfully saved.</p>
          <Button variant="light" onClick={() => setIsSuccess(false)}>
            X
          </Button>
        </Flex>
      </Banner>

      <Banner variant="error" show={Boolean(error)}>
        <Flex items="center" justify="space-between">
          <p>{error}</p>
          <Button variant="light" onClick={() => setError('')}>
            X
          </Button>
        </Flex>
      </Banner>

      {!entitySelected.entityId ? (
        <EntitySelectionForm
          type={type}
          tile={currentTile}
          onSubmit={(values) => setEntitySelected(values)}
        />
      ) : (
        <>
          <div style={{ display: showConfirmation ? 'none' : 'block' }}>
            <LogicForm
              type={type}
              entity={entitySelected}
              hasChanged={handleHasChanged}
              onCancel={handleCancel}
              tile={currentTile}
              loading={loading}
              onSubmit={handleSubmit}
            />
          </div>

          {showConfirmation && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                background: '#fff',
                padding: 30,
              }}
            >
              <ConfirmationModalStyles />
              <Flex items="flex-start" justify="space-between">
                <div>
                  <h1
                    style={{
                      marginBottom: '1rem',
                      textTransform: 'capitalize',
                      fontSize: '1.5rem',
                    }}
                  >
                    Leave page without saving?
                  </h1>
                  <p>
                    All unsaved information will be lost. Click cancel to to go
                    back to the logic form and confirm to close this screen.
                  </p>
                </div>
              </Flex>
              <Flex items="center" justify="flex-end">
                <Button
                  onClick={() => rest.history.goBack()}
                  variant="secondary"
                  type="button"
                  style={{ marginRight: 4 }}
                >
                  Yes, leave now
                </Button>
                <Button
                  onClick={() => setShowConfirmation(false)}
                  variant="primary"
                  type="button"
                >
                  No, go back
                </Button>
              </Flex>
            </div>
          )}
        </>
      )}
    </div>
  );
}

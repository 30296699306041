import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import _ from 'lodash';
import { getInputsForExperience } from '../utils';

export const DisconnectAppTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: 'content',
  input: getInputsForExperience({
    experience,
    mandatoryInputs: [
      C.experienceTileTitle(masonryEnabled),
      C.experienceTileDescription(masonryEnabled),
    ],
    optionalInputs: [
      ...C.dependencyCriteria({
        emitEvent: true,
        visibility: true,
        cancellation: true,
      }),
    ],
  }),
});

export default ({
  experience = default_experience_key,
  tiles,
  dataForTile
}) => {
  const {
    disconnect_app: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content`;

  const connectAppSlugs = _.filter(
    tiles,
    ({ content: { type } }) => type === 'connect_app',
  ).map(({ id }) => id);

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        {
          name: `connect_app_node_slug`,
          label: `Connect App Node Slug`,
          interface: `select`,
          options: connectAppSlugs,
          required: true,
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(DisconnectAppTileSettings(experience));
  }

  return schema;
};
